import React from 'react';
import Button from '@material-ui/core/Button';
import { Route, Link, withRouter } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogTitle from '@material-ui/core/DialogTitle';



class ErrorPopup extends React.Component {
	constructor(props){
	  super(props);

	  this.state={
      fehlermeldung : '',
      open : false
	}
}


//componentDidUpdate () {
//  console.log('new props');
//  this.setState({open : this.props.open});
//}

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.props.onErrorSMSClose(false);
  };


  handleClick(event){
  var self = this;
  let socket = this.props.socket
  var {history} = this.props
  socket.emit('errorsms',{fehlermeldung:this.state.fehlermeldung,daten:[this.props.daten.online,this.props.daten.userdata,this.props.daten.socket.connected,this.props.history.location]})
  socket.once('sms done', data => {
      console.log(data)
      if (data.status)
        this.props.onErrorSMSClose(true)
      else
          this.props.onErrorSMSClose(false)
        }
      )
  }






  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Fehler melden</DialogTitle>
          <DialogContent>
             <TextField
              multiline
               type="text"
               label="Fehlermeldung"
               value = {this.state.fehlermeldung}
               onChange = {(e) => this.setState({fehlermeldung:e.target.value})}
               />

            </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary"  onClick={(event) => this.handleClose()}> Abbrechen </Button>
             <Button variant="contained" color="primary" primary='true' onClick={(event) => this.handleClick(event)}>
             Absenden
             </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(ErrorPopup)
