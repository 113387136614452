import * as React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { Route, Link, withRouter } from "react-router-dom";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import DpbauContainer from './dp_bau_container';
import PrintIcon from '@material-ui/icons/Print';
import ListIcon from '@material-ui/icons/List';
import TextsmsIcon from '@material-ui/icons/Textsms';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import InsertChartIcon from '@material-ui/icons/InsertChart'
import Badge from '@material-ui/core/Badge';
//import HTML5Backend, { NativeTypes } from 'react-dnd-html5-backend';

const monate = ['Jan','Feb','März','April','Mai','Juni','Juli','Aug','Sept','Okt','Nov','Dez']
const jahre = ['2020','2021','2022']

const sstyle = {
  width: '100%',
  textAlign: 'center'
};

const odelstyle = {
  maringRight: '10px',
  textAlign: 'right',
  //display : 'none'
  //width : '70%'
};

const mstyle = {
  //width : '70%'
};

const viewmodstyle = {
  display: 'inline-flex',
  float: 'right'

}

const viewmodstyleleft = {
  display: 'inline-flex',
  float: 'left',
  marginLeft : '20px'
}

const notifies = {
  display: 'inline-flex',
    marginLeft: '15px',
}

const arrowstyle = {
  verticalAlign: 'middle',
}


class Dpbau extends React.Component {
  // von parent: Userdata, socket
  // holt vom server userlist (wenn admin)
  // gibt monat, jahr, selected_user (aus userdata oder userlist), allowed_wp (von userdata)
  constructor(props) {
    super(props)
    this.state = {
      monat : '09',
      jahr : '2018',
      manage : false,
      userlist : [],
      selected_user : '',
      selected_wp : false,
      buildview : 'calendar',
      sitemenu : false,
      statmenu : false,
      notifies_count : 0,
    }
  }

  componentDidMount () {
    const {params} = this.props.match ? this.props.match : false
    if (params && 'monat' in params) this.setState({monat : params.monat.substr(0,2), jahr : params.monat.substr(3,4)})
    if (params && 'wp' in params) this.setState({selected_wp : params.wp})
    if (params && 'buildview' in params) this.setState({buildview : params.buildview})
    if ((this.props.socket) && (this.props.userdata) && ('manage_wp' in this.props.userdata)) {
      if (!(params && 'wp' in params) || ((this.state.selected_wp) && this.props.userdata.manage_wp.indexOf(this.state.selected_wp) == -1))
              this.setState({selected_wp : this.props.userdata.manage_wp[0]});
      //this.setState({selected_wp : this.props.userdata.manage_wp[0]});
      this.setState({manage: true})
    //   socket.emit('userlist',{ wp: this.state.selected_wp });
    //   socket.on('userlist response', data => {
    //   this.setState({userlist : data});
    // });
      this.setState({selected_user : this.props.userdata.username});

    }
  }

  dbmonat= () => {    return this.state.monat+'.'+this.state.jahr  }


  componentDidUpdate (prevProps) {
      if (this.props !== prevProps) {

        const {params} = this.props.match ? this.props.match : false
        if (params && 'monat' in params) this.setState({monat : params.monat.substr(0,2), jahr : params.monat.substr(3,4)})
        if (params && 'wp' in params) this.setState({selected_wp : params.wp})
        if (params && 'view' in params) this.setState({buildview : params.view})
      if ((this.props.socket) && (this.props.userdata) && ('manage_wp' in this.props.userdata)) {
        if (!(this.state.selected_wp) && !(params && 'wp' in params) || this.props.userdata.manage_wp.indexOf(this.state.selected_wp) == -1)
        {
          console.log('update wp ')
          this.setState({selected_wp : this.props.userdata.manage_wp[0]});
        }
        //this.setState({selected_wp : this.props.userdata.manage_wp[0]});
        let socket = this.props.socket;
        this.setState({manage: true})
      //   socket.emit('userlist',{ wp: this.state.selected_wp });
      //   socket.on('userlist response', data => {
      //   this.setState({userlist : data});
      // });
      }
    }
  }


  componentWillUnmount () {
    let socket = this.props.socket
    if (socket)   socket.off('userlist response')
  }

  changeView = newview =>{
    if (newview !== this.state.buildview) {
        console.log(newview)
     this.setState({buildview : newview})
   }
  }

  toggleNotifies = c => {
    if (this.state.notifies_count !== c){
      console.log('notify change',c)
      this.setState({notifies_count : c})
    }
  }

  toggleSiteMenu = klick => {
    let old = this.state.sitemenu
    console.log('toggle sitemenu')
    this.setState({sitemenu : !old})
  }

  toggleStatMenu = klick => {
    let old = this.state.statmenu
    console.log('toggle sitemenu')
    this.setState({statmenu : !old})
  }


  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value }, () => {
    this.props.history.push('/DP-Bau/'+this.state.buildview+'/'+this.state.selected_wp+'/'+this.dbmonat())
  })
  };

  handleMonthArrow = (moveby) => {
    let m = parseInt(this.state.monat)
    let j = parseInt(this.state.jahr)
    m = m + moveby
    if (m < 1) {
      m = 12
      j = j - 1
    }
    if (m > 12) {
      m = 1
      j = j + 1
    }
    m = this.lz(m)
    this.setState({ monat: m, jahr : j }, () => {
    this.props.history.push('/DP-Bau/'+this.state.buildview+'/'+this.state.selected_wp+'/'+this.dbmonat())
  })
}



  lz = (n) => {
    return (n < 10 ? '0'+n : ''+n)
  }

  render(){
  return (
    <div>
    <div className="viewmod" style={viewmodstyleleft}>
        <div className="togglesite" style={odelstyle} onClick={ () => {this.toggleSiteMenu('klick')}} >
          <CalendarTodayIcon/>
        </div>
        <div className="togglesite" style={odelstyle} onClick={ () => {this.toggleStatMenu('klick')}} >
          <InsertChartIcon/>
        </div>
    </div>

    <div style={sstyle}>
    <ArrowBackIcon style={arrowstyle} onClick={e => this.handleMonthArrow(-1)}/>
  <FormControl style={mstyle}>
    <Select
      value={this.state.monat}
      onChange={this.handleChange}
      inputProps={{
        name: 'monat',
        id: 'monat_selector',
      }} >
      {monate.map((m,i) => <MenuItem key={i} value={this.lz(i+1)}>{m}</MenuItem>)}
    </Select>
  </FormControl>
  <FormControl>
    <Select
      value={this.state.jahr}
      onChange={this.handleChange}
      inputProps={{
        name: 'jahr',
        id: 'jahr_selector',
      }} >
        {jahre.map((j,i) => <MenuItem key={i} value={j}>{j}</MenuItem>)}
    </Select>
  </FormControl>
  <ArrowForwardIcon style={arrowstyle}  onClick={e => this.handleMonthArrow(1)}/>
  { (this.props.userdata.manage_wp) ?
  <FormControl style={mstyle}>
    <Select
      value={this.state.selected_wp || ''}
      onChange={this.handleChange}
      inputProps={{
        name: 'selected_wp',
        id: 'wp_selector',
      }} >
      {this.props.userdata.manage_wp.map((m,i) => <MenuItem key={i} value={m}>{m}</MenuItem>)}
    </Select>
  </FormControl>
  : null
  }
  {this.state.notifies_count > 0 &&
    <div className="notifies" style={notifies}>
    <Link to={'/DP-Bau/notifies/'+this.state.selected_wp+'/'+this.dbmonat()} style={{ textDecoration: 'none', color : 'black' }}>
      <div className="notifyview" style={odelstyle} onClick={ () => {this.changeView('notifies')}} >
        <Badge color="secondary" badgeContent={this.state.notifies_count}>
          <TextsmsIcon/>
        </Badge>
      </div>
    </Link>
    </div>
  }
  <div className="viewmod" style={viewmodstyle}>
  <Link to={'/DP-Bau/calendar/'+this.state.selected_wp+'/'+this.dbmonat()} style={{ textDecoration: 'none', color : 'black' }}>
    <div className="calenderview" style={odelstyle} onClick={ () => {this.changeView('calendar')}} >
      <CalendarTodayIcon/>
    </div>
  </Link>
  <Link to={'/DP-Bau/list/'+this.state.selected_wp+'/'+this.dbmonat()} style={{ textDecoration: 'none', color : 'black' }}>
    <div className="listview" style={odelstyle} onClick={ () => {this.changeView('list')}} >
      <ListIcon/>
    </div>
  </Link>
  <Link to={'/DP-Bau/print/'+this.state.selected_wp+'/'+this.dbmonat()} style={{ textDecoration: 'none', color : 'black' }}>
    <div className="printview" style={odelstyle} onClick={ () => {this.changeView('print')}} >
      <PrintIcon/>
    </div>
  </Link>
  </div>
</div>
  <div>

<Route path='/DP-Bau/:buildview/:wp/:monat' render={(props) =>
  <DpbauContainer {...props} showsitemenu={this.state.sitemenu} showstatmenu={this.state.statmenu} buildview={this.state.buildview} onViewChange={this.changeView}
      userdata={this.props.userdata} selected_wp={this.state.selected_wp} onNotifies={this.toggleNotifies}
        monat={this.state.monat} jahr={this.state.jahr} socket={this.props.socket}/>}/>

  </div>
</div>
)
  }

}

export default withRouter(Dpbau)
//export default DragDropContext(HTML5Backend)(Dpbau);
