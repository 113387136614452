import ax_ins from './ajax'


export function login (usr,pswd) {
  var data={
    username : usr,
    password : pswd
  }
  return ax_ins.post('login',data);
}


export function getsdata (socket,token) {
  console.log('start emit');
  socket.emit('my event', {data: 'I\'m connected!'});
  return socket.on('my response', data => console.log(data));
}
