import React from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

const tabstyle = {
margin: '5px',
marginTop: '-15px',
}

function TabContainer(props) {
  return (
    <Typography component="div" style={{paddingLeft : '5px'}}>
      {props.children}
    </Typography>
  );
}

export default class CurrentBooked extends React.Component {
  constructor(props){
    super(props)
    this.state={
      value : 0,

    }
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  componentDidMount () {
    console.log(this.props.allowed_wp)
  }

  componentDidUpdate (prevProps) {
    if (prevProps !== this.props) {
      console.log(this.props.allowed_wp)

    }
  }

  render () {
    const {allowed_wp, currentset,mon_conf} = this.props
    const { value } = this.state
    return (

      <div style={tabstyle}>
      <Tabs value={this.state.value} indicatorColor="primary"
          textColor="primary" onChange={this.handleChange} centered >
        {allowed_wp.map((wp,index) => {
          return (!mon_conf[wp].shiftshidden && (('ND' in currentset && wp in currentset.ND) || ('TD' in currentset && wp in currentset.TD)) && <Tab label={wp}/>)
        })}
      </Tabs>
      <Paper>
      {allowed_wp.map((wp,index) => {
        return (<TabContainer>

        {!mon_conf[wp].shiftshidden &&
          <div>
              <div>{value === index && 'TD' in currentset && wp in currentset.TD && 'TD: '+ currentset.TD[wp] }</div>
            <div>{value === index && 'ND' in currentset && wp in currentset.ND && 'ND: '+ currentset.ND[wp] }</div>
        </div>
        }
          </TabContainer>)
      })}
      </Paper>
      </div>
    )

  }


}
