import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Preday from '../days/pre_day';
import Calday from '../days/cal_day';
import DnDday from '../days/dnd_day';

import '../calendar.css';

const wota = ['Mo','Di','Mi','Do','Fr','Sa','So']
const monate = ['Jan','Feb','März','April','Mai','Juni','Juli','Aug','Sept','Okt','Nov','Dez']

class DPCalendar extends Component {
  //aus parent: selected_user, userdata, monat, jahr, socket
  //holt vom server: current_offers von selected_user
  //an Calday: current_offers[tag], tag
    // zurück offer_day
  //an OfferPopup: current_offers[offer_day], allowed_wp,
    // zurück wp -> shift -> true/false

  constructor(props) {
    super(props)

    //let datum = this.props.datum;
    var datum = '01.01.2022';
    this.state = {
      charge : {},
      response : false,
      offer_day : 0,
      offers : {},
      booked : {},
      inner_scroll : false,
      maxoffers : 6,
      weeks : 5

    }
}

  firstdayinmonth = () => {    return new Date(this.props.jahr,parseInt(this.props.monat,10)-1,0).getDay()  }
  daysinmonth = () => {    return new Date(this.props.jahr,parseInt(this.props.monat,10),0).getDate()  }
  weekday = (day) => {    return new Date(this.props.jahr,parseInt(this.props.monat,10)-1, day-1).getDay()  }
  dbmonat= () => {    return this.props.monat+'.'+this.props.jahr  }
  weeks = () => { return Math.ceil((this.firstdayinmonth()+this.daysinmonth())/7)}

  handleDnD = (data) => {
    //kommt tag,user, wohin gezogen
    //console.log(data)
    this.props.onDnD(data)
  }

  handleweekDblClck = () => {
    console.log('onDblClck')
    this.setState({inner_scroll: !this.state.inner_scroll})
  }

  componentDidMount () {
    this.setState({offers :this.props.offers})
    this.setState({booked : this.props.booked})
    if (this.props.socket) {

      // var socket = this.props.socket;
      // socket.emit('build month',{monat : this.dbmonat(), wp : this.props.selected_wp });
      // socket.on('build month response', data => { this.setState(data) });
      // socket.on('build day response', data => {
      //   let tag = data.tag
      //   let newbooked = this.state.booked
      //   newbooked[tag] = data.booked[tag]
      //   let newoffers = this.state.offers
      //   newoffers[tag] = data.offers[tag]
      //   this.setState({booked : newbooked})
      //   this.setState({offers : newoffers})
      //   });
    }
  }

  componentDidUpdate (prevProps) {
    if ((this.props !== prevProps) && (this.props.socket)) {
      this.setState({maxoffers : this.props.maxoffers})
      this.setState({weeks : this.weeks()})
      this.setState({firstdayinmonth : this.firstdayinmonth()})
        this.setState({daysinmonth : this.daysinmonth()})
      this.setState({offers :this.props.offers})
      this.setState({booked : this.props.booked})
      // let socket = this.props.socket;
      // console.log('get new month: '+this.dbmonat())
      // socket.emit('build month',{monat : this.dbmonat(), wp : this.props.selected_wp });
      // socket.on('build month response', data => { this.setState(data) });
      // socket.on('build day response', data => {
      //   let tag = data.tag
      //   let newbooked = this.state.booked
      //   newbooked[tag] = data.booked[tag]
      //   let newoffers = this.state.offers
      //   newoffers[tag] = data.offers[tag]
      //   this.setState({booked : newbooked})
      //   this.setState({offers : newoffers})
      //   });
      ////this.setState({allowed_wp : this.props.userdata.wp})
    }
  }

  render() {
    let plain = [];
    let day = [];

    // const style = {
    //   calday : {
    //     width: 'calc(100% / 7 - 1px)',
    //     height: 'calc(20px  * ( '+ this.state.maxoffers+' + 1 ) )',
    //     minHeight : 'calc(100% / '+this.weeks()+')',
    //     float: 'left',
    //     borderBottom: '1px solid #ccc',
    //     borderRight: '1px solid #ccc',
    //   }
    // }
    //
    // if (this.state.inner_scroll) {
    //     style.dtoffer['overflowY'] = 'auto'
    //     style.calday['height'] = 'calc(100% / '+this.weeks()+')'
    // }










      for(let i=0; i < this.firstdayinmonth();i++) {
        plain.push(<Preday weeks={this.state.weeks} maxoffers={this.state.maxoffers} innerscroll={this.state.inner_scroll} key={i} />);
      }
      for(let i=0; i<this.daysinmonth();i++) {
        day.push(<DnDday tag={i+1} key={i} weeks={this.state.weeks} userlist={this.props.userlist} highlightuser={this.props.highlightuser}  maxoffers={this.state.maxoffers} innerscroll={this.state.inner_scroll} current_offers={this.state.offers[i+1] || [] } current_booked={this.state.booked[i+1] || []} onDnD={this.handleDnD}/>);
      }


    return (
      <div className="Calendar">
        <div className="cal_head">
        {wota.map((wot,index) => <div className="wota" key={index} onDoubleClick={() => {this.handleweekDblClck()}}>{wot}</div>)}
        </div>
      <div className={"cal_body"+(this.props.highlightuser ? ' cal_body_dark' : '') } >
      {plain}
      {day}
      </div>
      <hr/>
      </div>

    );
  }
}





export default DPCalendar;
