import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import {isMobile} from 'react-device-detect'
import { login } from '../api'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
});

const pwresetstyle = {
  textAlign: 'center',
  fontSize : '75%'
}

export default class Loginpopup extends React.Component {
	constructor(props){
	  super(props);

	  this.state={
	  username:'',
	  password:'',
    stayloggedin: true,
    open :  false,
    counter : 0
	}
}

componentDidMount () {
  if (!isMobile) {
    this.setState({stayloggedin : false})
  }
}


//componentDidUpdate () {
//  console.log('new props');
//  this.setState({open : this.props.open});
//}

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleEnter = (e) => {
    if (e.key === 'Enter') {
      this.handleClick(e);
    }
  }

  handleClick(event){
  var self = this;
  login(this.state.username,this.state.password)
  .then(function (response) {
   if(response.status === 200){
      self.props.onGotuser(response.data,self.state.stayloggedin);
      //self.handleClose();
   }
   else{ console.log("Username does not exists"); }
 })
 .catch(function (error) {
   console.log(error);
   let counter = self.state.counter
   counter += 1
   self.setState({counter : counter})
 });
}


  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
        >
          <DialogTitle id="form-dialog-title">Login</DialogTitle>
          <DialogContent>
            <TextField
               helperText="Benutzernamen eingeben"
               label="Benutzername"
               onChange = {(e) => this.setState({username:e.target.value})}
             />
           <br/>
             <TextField
               type="password"
               helperText="Passwort eingeben"
               label="Passwort"
               onChange = {(e) => this.setState({password:e.target.value})}
               onKeyPress = {(e) => this.handleEnter(e)}
               />
            <br/>
            <FormControlLabel
              control={
              <Checkbox
              checked = {this.state.stayloggedin}
                onChange={(event) => this.setState({stayloggedin : event.target.checked})}
                color="primary"
                />
            }
            label = 'Angemeldet bleiben'/>
            {this.state.counter > 2 &&
              <div style={pwresetstyle} onClick={() => this.props.resetpw()} >Passwort vergessen</div>
            }
            </DialogContent>
          <DialogActions>
             <Button variant="contained" color="primary" primary='true' onClick={(event) => this.handleClick(event)}>
             Absenden
             </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
