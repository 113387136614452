import * as React from 'react';
import WPManage from './wp_manage'
import Paper from '@material-ui/core/Paper';

const container = {
  display : 'inline-block',
  width:'100%',
}

const subcontainer = {
  minWidth :'400px',
  paddingLeft : '20px',
  paddingBottom : '20px',
  float : 'left'
}


class DPMcontainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        wp: false
    }

  }

  componentDidMount () {
    this.setState({wp : this.props.userdata.manage_wp})

  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
        this.setState({wp : this.props.userdata.manage_wp})
    }
  }

  render () {


    return (
        <div style={container}>
        {this.state.wp && this.state.wp.map((wp,i) =>
          <div style={subcontainer} key={i}>
          <Paper>
            <WPManage {...this.props} selected_wp={wp}/>
          </Paper>
          </div>
        )}
        </div>
    )


  }


}

export default DPMcontainer;
