import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
});
const swal = withReactContent(Swal)

export default class setUserPW extends React.Component {
	constructor(props){
	  super(props);

	  this.state={
      oldpassword : '',
      newpassword : '',
      newpassworddouble : '',
      wrongold : true,
      notthesame : true,
      open :  false,
	}
}


//componentDidUpdate () {
//  console.log('new props');
//  this.setState({open : this.props.open});
//}

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };


  handleClick(event){
  var self = this;
  let socket = this.props.socket
  var {history} = this.props
  socket.emit('changepw',{oldpassword:this.state.oldpassword,newpassword:this.state.newpassword})
  socket.once('pwchanged', data => {
    if ('pw' in data && data.pw == 'changed') {
        sessionStorage.removeItem('access_token')
        localStorage.removeItem('access_token')
        this.props.onPWChangeClose(true)
      }
  })
  }

  checkOldPW = () => {
    let socket = this.props.socket
    socket.once('pwchecked', data => {
      if (!('checked' in data && data.checked)) {
        this.setState({wrongold: true})
      } else {
        this.setState({wrongold: false})
      }
    })
    socket.emit('checkpw',{password : this.state.oldpassword})
  }

  checkdouble = (e,w) => {
    if (w == 'newpassworddouble') {
      this.setState({newpassworddouble:e.target.value})
      if (this.state.newpassword != e.target.value || this.state.newpassword == "") {  this.setState({notthesame : true})  }  else {  this.setState({notthesame : false}) }
    }
    if (w == 'newpassword') {
      this.setState({newpassword:e.target.value})
      if (this.state.newpassworddouble != e.target.value || this.state.newpassworddouble == "") {  this.setState({notthesame : true})  }  else {  this.setState({notthesame : false}) }
    }
  }



  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Passwort ändern</DialogTitle>
          <DialogContent>
             <TextField
               error = {this.state.wrongold}
               type="password"

               label="Aktuelles Passwort"
               value = {this.state.oldpassword}
               onChange = {(e) => this.setState({oldpassword:e.target.value})}
               onBlur = {(e) => this.checkOldPW()}
               />
               <br/>
             <TextField
                 type="password"

                 label="Neues Passwort"
                 value={this.state.newpassword}
                  onChange = {(e) => this.checkdouble(e,'newpassword')}
               />
              <br/>
               <TextField
                   error = {this.state.notthesame}
                   type="password"

                   label="Neues Passwort"
                   value = {this.state.newpassworddouble}
                     onChange = {(e) => this.checkdouble(e,'newpassworddouble')}
                 />
            <br/>
            </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary"  onClick={(event) => this.props.onPWChangeClose()}> Abbrechen </Button>
             <Button variant="contained" color="primary" primary='true' disabled={this.state.wrongold || this.state.notthesame} onClick={(event) => this.handleClick(event)}>
             Absenden
             </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
