import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Preday from '../days/pre_day';
import Calday from '../days/cal_day';
import DnDday from '../days/dnd_day';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DTBookedShiftPlace from '../dndelements/dtshiftplace'
import DTOfferShiftPlace from '../dndelements/dtofferplace'
import '../calendar.css'

const wota = ['Mo','Di','Mi','Do','Fr','Sa','So']
const monate = ['Jan','Feb','März','April','Mai','Juni','Juli','Aug','Sept','Okt','Nov','Dez']

const FT = {'2018':{'10':[3],'11':[1],
'12':[25,26]},'2019':{'01':[1],'04':[19,22],
'05':[1,30],'06':[10,20],'10':[3],'11':[1],'12':[25,26] } }

const style = {
  marginRight :'auto',
  marginLeft : 'auto'
}



class DPListe extends Component {
  //aus parent: selected_user, userdata, monat, jahr, socket
  //holt vom server: current_offers von selected_user
  //an Calday: current_offers[tag], tag
    // zurück offer_day
  //an OfferPopup: current_offers[offer_day], allowed_wp,
    // zurück wp -> shift -> true/false

  constructor(props) {
    super(props)

    //let datum = this.props.datum;
    var datum = '01.09.2018';
    this.state = {
      charge : {},
      response : false,
      offer_day : 0,
      offers : {},
      booked : {},
      td : [],
      nd : []

    }
}

  firstdayinmonth = () => {    return new Date(this.props.jahr,parseInt(this.props.monat,10)-1,0).getDay()  }
  daysinmonth = () => {    return new Date(this.props.jahr,parseInt(this.props.monat,10),0).getDate()  }
  weekday = (day) => {    return new Date(this.props.jahr,parseInt(this.props.monat,10)-1, day-1).getDay()  }
  dbmonat= () => {    return this.props.monat+'.'+this.props.jahr  }

  handleDnD = (data) => {
    //kommt tag,user, wohin gezogen
    //console.log(data)
    this.props.onDnD(data)
  }


  componentDidMount () {
    this.setState({offers :this.props.offers})
    this.setState({booked : this.props.booked})
    // let tag = this.props.tag
    // let cb = this.props.current_booked
    // let co = this.props.current_offers
    // this.setState({td : ('TD' in cb && cb['TD']) ? cb['TD'] : []})
    // this.setState({nd : ('ND' in cb && cb['ND']) ? cb['ND'] : []})
    // this.setState({offers : ('offer' in co)  ? co['offer'] : []})
  }

  componentDidUpdate (prevProps) {
    if (this.props !== prevProps) {
      this.setState({offers :this.props.offers})
      this.setState({booked : this.props.booked})
      // let tag = this.props.tag
      // let cb = this.props.current_booked
      // let co = this.props.current_offers
      // this.setState({td : ('TD' in cb && cb['TD']) ? cb['TD'] : []})
      // this.setState({nd : ('ND' in cb && cb['ND']) ? cb['ND'] : []})
      // this.setState({offers : ('offer' in co)  ? co['offer'] : []})
      }
  }

  render() {
    var plain = [];
    var day = [];

    const isft = (tag) => {
      if (this.props.jahr in FT && this.props.monat in FT[this.props.jahr]  && FT[this.props.jahr][this.props.monat].indexOf(tag) !== -1 ) {
        return 'wt_FT'
    }
      else {
        return ''
      }
    }


     for (let i=0; i<this.daysinmonth();i++) {
        day.push(
            <TableRow key={i} className={isft(i+1)+' '+'wt_'+wota[this.weekday(i+1)]} >
              <TableCell component="th" scope="row" padding="dense">
                {(i+1)+'.'+this.props.monat+', '+wota[this.weekday(i+1)]}
              </TableCell>
              <TableCell padding="dense"><DTBookedShiftPlace listview="dbaulistview" type='TD' tag={i+1} onDnD={this.handleDnD} userlist={this.props.userlist}
                  rowelemcount={i+1 in this.state.offers && this.state.offers[i+1] ? this.state.offers[i+1].offer.length :2}
              current_booked={i+1 in this.state.booked && this.state.booked[i+1] && 'TD' in this.state.booked[i+1] && this.state.booked[i+1]['TD'] ? this.state.booked[i+1]['TD'] :[] }/></TableCell>
              <TableCell padding="dense"><DTBookedShiftPlace listview="dbaulistview" type='ND' tag={i+1} onDnD={this.handleDnD} userlist={this.props.userlist}
                  rowelemcount={i+1 in this.state.offers && this.state.offers[i+1] ? this.state.offers[i+1].offer.length : 2}
              current_booked={i+1 in this.state.booked && this.state.booked[i+1] && 'ND' in this.state.booked[i+1] && this.state.booked[i+1]['ND'] ? this.state.booked[i+1]['ND'] : [] }/></TableCell>
              <TableCell padding="dense"><DTOfferShiftPlace type='Offer' listview="dbaulistview" tag={i+1} userlist={this.props.userlist}
                            onDnD={this.handleDnD} current_offers={i+1 in this.state.offers && this.state.offers[i+1] ? this.state.offers[i+1].offer : []}/></TableCell>
            </TableRow>
            )
        }



    return (
      <div className="Liste" style={style}>
      <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Datum</TableCell>
            <TableCell>Tagdienst</TableCell>
            <TableCell>Nachtdienst</TableCell>
            <TableCell>Wünsche</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {day}
        </TableBody>
      </Table>
    </Paper>
      </div>

    );
  }
}

DPListe.propTypes = {
  userdata: PropTypes.object.isRequired
};



export default DPListe;
