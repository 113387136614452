import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CancelIcon from '@material-ui/icons/Cancel';
import LockIcon from '@material-ui/icons/Lock';
import ax_ins from '../../ajax'
import ShiftList from './shiftlist'
import { Document, Page } from  'react-pdf/dist/entry.webpack'

import { withStyles } from '@material-ui/core/styles';

const pdfcontainer = {
  width: '80%',
  height :'60vh'
}


export default class InvoiceDetail extends React.Component {
  	constructor(props){
  	  super(props);
      this.state = {
        numPages: null,
        pageNumber: 1,
        shiftlist : false
      }
  }

  componentDidMount () {
    let s = this.props.socket
    s.on('invoice detail', data => {


    })


  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.invoiceid) {
        this.setState({file: 'https://na.rdhs.eu/rest/rechnungdirekt/'+this.props.invoiceid+'.pdf'})
        ax_ins.get('rechnungdirekt/'+this.props.invoiceid,
          {responseType: 'blob',  // VERY IMPORTANT
          headers: {'Accept': 'application/pdf','Content-Type': 'text/'}})
        .then((response) => {
          const blob = new Blob([response.data])
          const url = window.URL.createObjectURL(blob)
          this.setState({fileUrl: url})
        })


      }

    }
  }

  onDocumentLoadSuccess = ({ numPages }) => {
      this.setState({ numPages });
    }

  downloadclick = (e) => {
      e.preventDefault()
    var a = document.createElement("a");
    document.body.appendChild(a);
    fetch(this.state.file).then(resp => resp.blob()).then(blob => {
    console.log(blob)
    let newblob = new Blob([blob], {type: "octet/stream"})
    let url = window.URL.createObjectURL(newblob)
    a.style = "display: none";
    a.href = url;
    a.download = 'rechnung.pdf'
    a.click();
    window.URL.revokeObjectURL(this.state.file);
  })
  console.log(e)
  }

  bloburl = () => {
    fetch(this.state.file).then(resp => resp.blob()).then(blob => {
    console.log(blob)
    //return blob
    let newblob = new Blob([blob], {type: blob.type})
    let url = window.URL.createObjectURL(newblob)
    console.log(url)
    return 'blob:'+url
  })
}

handleClose = () => {
  this.props.closeme(true);
}

  render() {
    const {pageNumber, numPages, fileUrl} = this.state

    return(
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title">Rechnungsdetails</DialogTitle>
        <Button variant="contained" color="secondary" onClick={event => this.downloadclick(event)}>
          Herunterladen
        </Button>
        <DialogContent>
        {fileUrl &&
        <div style={pdfcontainer}>
          <Document
             file={this.state.fileUrl}
             onLoadSuccess={this.onDocumentLoadSuccess}
             >
             <Page pageNumber={pageNumber}
                  scale={1}/>
           </Document>
           <p>Seite {pageNumber} von {numPages}</p>
        </div>
      }
        <Button variant="contained" color="secondary" onClick={event => this.setState({shiftlist : true})}>
          Dienste dieser Rechnung
        </Button>
        </DialogContent>
        <ShiftList {...this.props} open={this.state.shiftlist} closeme={e => this.setState({shiftlist : false})}/>
      </Dialog>
    )

  }


}
