import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Preday from '../days/pre_day_classic';
import Calday from '../days/cal_day';
import OfferPopup from '../days/offer';
import odiff from "odiff";
import update from 'immutability-helper';
import '../calendar.css';


const wota = ['Mo','Di','Mi','Do','Fr','Sa','So']
const monate = ['Jan','Feb','März','April','Mai','Juni','Juli','Aug','Sept','Okt','Nov','Dez']



class CalendarSwipeView extends Component {
  //aus parent: selected_user, userdata, monat, jahr, socket
  //holt vom server: current_offers von selected_user
  //an Calday: current_offers[tag], tag
    // zurück offer_day
  //an OfferPopup: current_offers[offer_day], allowed_wp,
    // zurück wp -> shift -> true/false

  constructor(props) {
    super(props)

    //let datum = this.props.datum;
    var datum = '01.09.2018';
    this.state = {
      charge : {},
      cur_user : {},
      response : false,
      show_offer_popoup : false,
      offer_day : 0,
      allowed_wp : [],
      manage_wp : [],
      perfectheight : false,

    }
}

  firstdayinmonth = () => {    return new Date(parseInt(this.props.jahr,10),parseInt(this.props.monat,10)-1,0).getDay()  }
  daysinmonth = () => {    return new Date(parseInt(this.props.jahr,10),parseInt(this.props.monat,10),0).getDate()  }
  weekday = (day) => {    return new Date(parseInt(this.props.jahr,10),parseInt(this.props.monat,10)-1, day-1).getDay()  }
  dbmonat= () => {    return this.props.monat+'.'+this.props.jahr  }
  weeks = () => { return Math.ceil((this.firstdayinmonth()+this.daysinmonth())/7)}


  componentDidMount () {
      let h = this.div.getBoundingClientRect().top
      console.log(this.div.getBoundingClientRect())
      this.setState({perfectheight : this.props.perfectheight})
    }

  componentDidUpdate (prevProps) {
    if (this.props !== prevProps)  {
      this.setState({perfectheight : this.props.perfectheight})
      }
   }






  render() {
    var plain = [];
    var day = [];


      for(var i=0; i < this.firstdayinmonth();i++) {
        plain.push(<Preday weeks={this.weeks()} key={i}/>);
      }
      for(i=0; i<this.daysinmonth();i++) {
        day.push(<Calday tag={i+1} key={i} weeks={this.weeks()} mon_conf={this.state.mon_conf}
        allowed_wp={[]} monat={this.props.monat} jahr={this.props.jahr}
        weekday={this.weekday(i+1)} current_offers={[]} /> );
      }
    //  this.get_month_dp();

//    });
  // const perfectheight = () => {
  //   height : this.state.perfectheight
  // }


    return (

      <div className="Calendar">
        <div className="cal_head">
        {wota.map((wot,index) => <div className="wota" key={index}>{wot}</div>)}
        </div>
      <div className="cal_body" ref={ div => { this.div = div } } style={{height : this.state.perfectheight}}>
      {plain}
      {day}
      </div>
      </div>


    );
  }
}


export default CalendarSwipeView;
