import React, { Component } from 'react';
import Calendar from './calendar';
import CalendarSwipeView from './calendar_swipeview';
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper'
import Dienstplan from './dienstplan'
import Swipeable from 'react-swipeable'
import DPStats from '../manager/dpstats'
import {isMobile} from 'react-device-detect'
import { ArrowBack, ArrowForward, MissedVideoCall } from '@material-ui/icons';
const monate = ['Jan','Feb','März','April','Mai','Juni','Juli','Aug','Sept','Okt','Nov','Dez']
const jahre = ['2022','2023','2024']

const sstyle = {
  width: '100%',
  textAlign: 'center'
};

const bgstyle= {
  backgoundImage: `url(./logo_bg.png)`,
  backgroundSize : 'contain'
}

const mstyle = {
  //width : '70%'
};

class Calendarcontainer extends Component {
  // von parent: Userdata, socket
  // holt vom server userlist (wenn admin)
  // gibt monat, jahr, selected_user (aus userdata oder userlist), allowed_wp (von userdata)
  constructor(props) {
    super(props)
    this.state = {
      monat : '',
      jahr : '',
      manage : false,
      userlist : [],
      selected_user : '',
      selected_wp : '',
      wpmultiselect : false,
      transform : 1,
      perfectheight : 500,
      gotheight : false,
      perfecttop : 100
    }
  }

  componentDidMount () {
    if ((this.props.socket) && (this.props.userdata)) {
      var socket = this.props.socket;
      if (this.props.ismanager) {
      socket.emit('userlist',{'get': 'it'});
      socket.on('userlist response', data => {
      this.setState({userlist : data});
    });
  }

    this.setState({selected_user : this.props.userdata.username});
    this.setState({selected_wp : this.props.userdata.wp[0]})
   const {params } = this.props.match ? this.props.match : false
   console.log(params)
  if (!('monat' in params)) {
    console.log('push-')
    let x = new Date()
    let mon = (x.getMonth()+1 < 10 ? '0'+(x.getMonth()+1) : ''+(x.getMonth()+1))
    this.setState({jahr : x.getFullYear()})
    this.setState({monat: mon})
    this.props.history.push('/calendar/'+mon+'.'+x.getFullYear())
  } else {
    this.setState({monat : params.monat.substr(0,2), jahr : params.monat.substr(3,4)})
  }
  }

  }

  componentDidUpdate (prevProps) {
      if ((this.props !== prevProps) && (this.props.socket) && (this.props.userdata)) {
        var socket = this.props.socket;
        if (this.props.ismanager) {
        socket.emit('userlist',{'get': 'it'});
        if ( socket._callbacks['userlist response'] == undefined ) {
          	socket.on('userlist response', data => {
              this.setState({userlist : data});
            });
        }
        }
      this.setState({selected_user : this.props.userdata.username});
      this.setState({selected_wp : this.props.userdata.wp[0]})
         const {params } = this.props.match ? this.props.match : false
        if (!('monat' in params)) {
          console.log('push-')
          let x = new Date()
          let mon = (x.getMonth()+1 < 10 ? '0'+(x.getMonth()+1) : ''+(x.getMonth()+1))
          this.setState({jahr : x.getFullYear()})
          this.setState({monat: mon})
          this.props.history.push('/calendar/'+mon+'.'+x.getFullYear())
        } else  {
          this.setState({monat : params.monat.substr(0,2), jahr : params.monat.substr(3,4)})
        }
      }

  }

  componentWillUnmount () {
    let socket = this.props.socket
    socket.off('userlist response')
  }

  modmonat = (n) => {
    let m = parseInt(this.state.monat)
    m  = m+n
    if (m > 12) {
      m = m - 12
      let j = parseInt(this.state.jahr)
      this.setState({jahr : ''+(j+1)})
    }
    else if (m <= 0) {
      m = 12 - m
      let j = parseInt(this.state.jahr)
      this.setState({jahr : ''+(j-1)})
    }
    return this.lz(m)
  }


  swipeUp =(e, deltaY, isFlick) => {
    let x = deltaY
    let y = Math.floor(this.state.perfectheight / 2)
    let func = (y-x)/y
    if (func <= 1 && func >= 0)
      this.setState({transform : func })

  }

  swipeDown =(e, deltaY, isFlick) => {
    let x = deltaY
    let y = Math.floor(this.state.perfectheight / 2)
    let func = (y-x)/y
    if (func <= 1 && func >= 0)
      this.setState({transform : func })
  }


  swipedUp =(e, deltaY, isFlick) => {
    e.preventDefault()
    if (deltaY > (this.state.perfectheight / 3)) {
      this.setState({monat : this.modmonat(1)})
    }
    this.setState({transform : 1})
  }

  swipedDown  = (e, deltaY, isFlick) => {
    e.preventDefault()
    if (deltaY < - (this.state.perfectheight / 3)) {
      this.setState({monat : this.modmonat(-1)})
    }
    this.setState({transform : 1})
  }


  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name == 'monat')
      this.props.history.push(event.target.value+'.'+this.state.jahr)
    else if ( event.target.name == 'jahr')
      this.props.history.push(this.state.monat+'.'+event.target.value)
  };

  togglewpmultiselect = (b) => {
    this.setState({wpmultiselect : b})
  }

  perfectheight = (h) => {
    console.log(h)
    this.setState({perfectheight : h.height})
      this.setState({perfecttop : h.top})
    this.setState({gotheight : true})
  }

  lz = (n) => {
    return (n < 10 ? '0'+n : ''+n)
  }

  render(){
const { classes } = this.props;


const transform = {
  transform : `scale(${this.state.transform})`,
  transformOrigin : 'center',
  background : '#fdfdfd82'
}

const countertransform = {
  transform : `scale(${1-this.state.transform})`,
  transformOrigin : 'center',
  position: 'absolute',
  top: this.state.perfecttop-15,
  background : '#fdfdfd82'

}

  return (
    <div>
    <div style={sstyle}>
    <ArrowBack onClick={e =>  this.setState({monat : this.modmonat(-1)})} style={{verticalAlign:'middle',marginRight:'0.5em'}}/>
  <FormControl style={mstyle}>
    <Select
      value={this.state.monat}
      onChange={this.handleChange}
      inputProps={{
        name: 'monat',
        id: 'monat_selector',
      }} >
      {monate.map((m,i) => <MenuItem key={i} value={this.lz(i+1)}>{m}</MenuItem>)}
    </Select>
  </FormControl>
  <FormControl>
    <Select
      value={this.state.jahr}
      onChange={this.handleChange}
      inputProps={{
        name: 'jahr',
        id: 'jahr_selector',
      }} >
        {jahre.map((j,i) => <MenuItem key={i} value={j}>{j}</MenuItem>)}
    </Select>
  </FormControl>
  <ArrowForward onClick={e =>  this.setState({monat : this.modmonat(1)})} style={{verticalAlign:'middle',marginLeft:'0.5em'}}/>
  {this.state.wpmultiselect &&
    <FormControl >
      <Select
        value={this.state.selected_wp || ''}
        onChange={(e) => this.setState({selected_wp: e.target.value})}
        inputProps={{
          name: 'selected_wp',
          id: 'wp_selector',
        }} >
        {this.props.userdata.wp.map((m,i) => <MenuItem key={i} value={m}>{m}</MenuItem>)}
      </Select>
      </FormControl>
  }
  </div>
  { (this.state.userlist) && this.props.ismanager ?
    <div  style={sstyle}>
  <FormControl style={mstyle}>
    <Select
      value={this.state.selected_user}
      onChange={this.handleChange}
      inputProps={{
        name: 'selected_user',
        id: 'monat_selector',
      }} >
      {this.state.userlist.map((m,i) => <MenuItem key={i} value={m.username}>{m.name.first+' '+m.name.last}</MenuItem>)}
    </Select>
  </FormControl>
  </div>
  : null
}
  <div>
  {this.props.socket.connected && this.state.selected_user &&
    <Swipeable  onSwipedDown={this.swipedDown} disabled={this.props.history.location.pathname.indexOf('calendar') == -1 }  onSwipedUp={this.swipedUp}
              onSwipingUp={this.swipeUp}  onSwipingDown={this.swipeDown} preventDefaultTouchmoveEvent={true} >
      <Switch>

          <Route path='/calendar/:monat' render={(props) =>
            <div style={{background : `url(../logo_bg.png) no-repeat center center fixed`, backgroundSize :`contain`}}>
              <Paper style={transform}>
                <Calendar  {...props}  userdata={this.props.userdata} onGetPerfectHeight={this.perfectheight} gotheight={this.state.gotheight} selected_user={this.state.selected_user} monat={this.state.monat} jahr={this.state.jahr} socket={this.props.socket}/>
              </Paper>
              <Paper style={countertransform}>
                <CalendarSwipeView  {...props}  userdata={this.props.userdata} perfectheight={this.state.perfectheight} selected_user={this.state.selected_user} monat={this.lz(parseInt(this.state.monat)+1)} jahr={this.state.jahr} />
              </Paper>
            </div>
          } />
          <Route path='/dienstplan/:monat' render={(props) => <Dienstplan  {...props} togglewpmultiselect={this.togglewpmultiselect} userdata={this.props.userdata} selected_user={this.state.selected_user} monat={this.state.monat} selected_wp={this.state.selected_wp} jahr={this.state.jahr} socket={this.props.socket}/>}/>
            <Route path='/dpstats/:monat' render={(props) => <DPStats  {...props} togglewpmultiselect={this.togglewpmultiselect}
                userdata={this.props.userdata} selected_user={this.state.selected_user}
                monat={this.state.monat} selected_wp={this.state.selected_wp}
                jahr={this.state.jahr} socket={this.props.socket}/>}/>

          </Switch>
        </Swipeable>
      }
  </div>
  </div>
)
  }

}

export default withRouter(Calendarcontainer)
