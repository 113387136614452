import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../days/days.css';
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'
import CommentIcon from '@material-ui/icons/Comment'
import {DragSource,	ConnectDragSource,	DragSourceConnector,	DragSourceMonitor,} from 'react-dnd'

const Types = {
  SHIFT: 'shift'
};


const shiftSource = {
	beginDrag(props) {
		return {
			name: props.name,
		}
	},
}

const commenticon = {
  display : 'flex',
  float: 'left',
  marginRight: '0px',
  fontSize : '20px',
}


function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDragSource: connect.dragSource(),
    // You can ask the monitor about the current drag state:
    isDragging: monitor.isDragging()
  };
}

const dragpart = {
  beginDrag(props, monitor, component) {
  // Return the data describing the dragged item
    const item = { props };
    return item;
  },

  endDrag(props, monitor, component) {
    console.log('end drag');

    //console.log(monitor.getDropResult());

   }
}

class Shift extends Component {
  constructor(props) {
    super(props)
  }

handleDblClck = (shift) => {
  this.props.onDblClck(shift)
}




  render() {
    let s = this.props.shift;
    let cert = this.props.cert;

    const inners = {
      overflowY :'auto'
    }

    const { isDragging, connectDragSource } = this.props;
    return connectDragSource(
            <div  onDoubleClick={() => {this.handleDblClck(s)}} className={('shift_'+this.props.type+(this.props.twin && !this.props.listview ? ' twin ' : ' '))
            +(this.props.listview ? 'listshift ' : ' ')
            +(this.props.highlightuser && this.props.highlightuser !== s.fullname ? 'unhighlightuser' : '')}>
            {s.comments &&
              <Tooltip title={s.comments} TransitionComponent={Zoom} leaveDelay={200}>
                <CommentIcon style={commenticon}/>
              </Tooltip>
            }
            {!cert && <span style={{color:'red'}}>x</span>}
            {s.fullname}<div className="left"></div><div className="right"></div>
            </div>
    )
  }
}

export default DragSource((props) => {return props.shift.shift+'-'+props.tag;},dragpart,collect)(Shift);
