import * as React from 'react';
import DPCalendar from './dpcalendar';
import DPListe from './dpliste';
import DPprint from './dpprint'
import DPnotify from './dpnotify'
import { Route, Switch, Redirect } from "react-router-dom";
import DnDSiteMenu from '../dndelements/dndsitemenu'
import StatSiteMenu from './statsitemenu'
import DeleteIcon from '@material-ui/icons/Delete'


const style = {
  display: 'flex'
}

const odelstyle = {
  margingRight: '10px',
  textAlign: 'right',
  marginTop : '-20px'
  //width : '70%'
};

class DpbauContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      monat : '08',
      jahr : '2018',
      manage : false,
      offers : {},
      booked : {},
      notify : [],
      showsitemenu : false,
      userlist : [],
      maxoffers : 7,
      gotonetime : false,
      highlightuser : false,
      userdict : {}
    }
  }

  firstdayinmonth = () => {    return new Date(this.props.jahr,parseInt(this.props.monat,10)-1,0).getDay()  }
  daysinmonth = () => {    return new Date(this.props.jahr,parseInt(this.props.monat,10),0).getDate()  }
  weekday = (day) => {    return new Date(this.props.jahr,parseInt(this.props.monat,10), day).getDay()  }
  dbmonat= () => {    return this.props.monat+'.'+this.props.jahr  }

  changeView = (where) => {
    if (where !== this.props.buildview)  this.props.onViewChange(where)
  }

  handleDnD = (data) => {
    //kommt tag,user, wohin gezogen
    //console.log(data)
    data['monat'] = this.dbmonat();
    data['wp'] =  this.props.selected_wp;
    console.log(data.was)
    let socket = this.props.socket;
    if (data.type === 'deldrop') {
        console.log(data)
        socket.emit('book shift',data);
    }
    else {
      if (data.was ==='unknown' ) {
        if( data.type == 'TD' || data.type == 'ND') {data.type = 'Offer'}
        else {data.type = data.is.shift}
      }
      socket.emit('book shift',data);
  }
  }

  componentDidMount () {
    if (this.props.socket && this.props.selected_wp) {
      console.log('get month '+this.dbmonat())
      var socket = this.props.socket;
      socket.emit('build month',{monat : this.dbmonat(), wp : this.props.selected_wp });
      socket.on('build month response', data => {
        this.setState({gotonetime : true})
        this.setState(data)
        if (data.notify)
          this.props.onNotifies(data.notify.length)
      });
      socket.on('build day response', data => {
        if (!('error' in data)) {
        let tag = data.tag
        let newbooked = this.state.booked
        newbooked[tag] = data.booked[tag]
        let newoffers = this.state.offers
        newoffers[tag] = data.offers[tag]
        this.setState({maxoffers : data.maxoffers})
        this.setState({notify: data.notify})
        this.setState({booked : newbooked})
        this.setState({offers : newoffers})
        console.log(data.notify,data.notify.length)
        if (data.notify)
          this.props.onNotifies(data.notify.length)
      } else console.log(data);
        })   
        socket.emit('userlist',{ wp: this.props.selected_wp });
        socket.on('userlist response', data => {
        this.setState({userlist : data});
        this.setState({userdict : Object.assign({},...data.map(item => ({[item.username]: item.cert[0]})))})
        
      });
    }
  }

  componentDidUpdate (prevProps) {
    if ((this.props !== prevProps) && (this.props.socket.connected) && this.props.selected_wp) {
    if (this.props.selected_wp !== prevProps.selected_wp || this.props.monat !== prevProps.monat ||
      this.props.jahr !== prevProps.jahr || this.props.userdata !== prevProps.userdata || !this.state.gotonetime )
    {
      let socket = this.props.socket;
      console.log('get new month: '+this.dbmonat())
      socket.emit('build month',{monat : this.dbmonat(), wp : this.props.selected_wp });
      if ( socket._callbacks['build month response'] == undefined ) {
        socket.on('build month response', data => {
           this.setState(data)
           this.setState({gotonetime : true})
           this.props.onNotifies(this.state.notify.length)
         });
     }
     if ( socket._callbacks['build day response'] == undefined ) {
      socket.on('build day response', data => {
        if (!('error' in data)) {
          let tag = data.tag
          let newbooked = this.state.booked
          newbooked[tag] = data.booked[tag]
          let newoffers = this.state.offers
          newoffers[tag] = data.offers[tag]
          this.setState({maxoffers : data.maxoffers})
          this.setState({notify: data.notify})
          this.setState({booked : newbooked})
          this.setState({offers : newoffers})
          this.props.onNotifies(this.state.notify.length)
      } else console.log(data)  })
    }
      if (this.props.selected_wp !== prevProps.selected_wp) {
        socket.emit('userlist',{ wp: this.props.selected_wp });
        socket.on('userlist response', data => {
        this.setState({userlist : data});
        this.setState({userdict : Object.assign({},...data.map(item => ({[item.username]: item.cert[0]})))})
        
      });
    }
    }
    if (this.props.showsitemenu != prevProps.showsitemenu) {this.setState({showsitemenu : this.props.showsitemenu})}

  }
  }

  componentWillUnmount() {
    let socket = this.props.socket
    socket.off('build month response')
    socket.off('build day response')
  }

  highlightuser = (u) => {
    console.log(u)
    this.setState({highlightuser : u})
  }

  render (){

    return (
      <div>
      {this.props.socket &&
              <div>
                    {this.state.showsitemenu && <DnDSiteMenu userlist={this.state.userlist} socket={this.props.socket} selected_wp={this.props.selected_wp} onDnD = {this.handleDnD}/>}
                    {this.props.showstatmenu && <StatSiteMenu highlightuser={this.highlightuser} socket={this.props.socket} selected_wp={this.props.selected_wp} monat={this.dbmonat()}/>}
                <div style={style}>
                <Switch>
                <Route path='/DP-Bau/calendar/:wp/:monat' render={(props) => <DPCalendar {...props} highlightuser={this.state.highlightuser} data={this.props.userdata} selected_wp={this.props.selected_wp} offers={this.state.offers}
                    booked={this.state.booked} monat={this.props.monat} jahr={this.props.jahr} socket={this.props.socket} userlist={this.state.userdict} onDnD = {this.handleDnD} />}/>
                <Route path='/DP-Bau/list/:wp/:monat' render={(props) => <DPListe {...props} userdata={this.props.userdata} selected_wp={this.props.selected_wp} offers={this.state.offers}
                        booked={this.state.booked} monat={this.props.monat} jahr={this.props.jahr} socket={this.props.socket}  userlist={this.state.userdict} onDnD = {this.handleDnD} />}/>
                <Route path='/DP-Bau/print/:wp/:monat' render={(props) => <DPprint {...props} userdata={this.props.userdata} selected_wp={this.props.selected_wp} offers={this.state.offers}
                        booked={this.state.booked} monat={this.props.monat} jahr={this.props.jahr} socket={this.props.socket} userlist={this.state.userdict} onDnD = {this.handleDnD} />}/>
                <Route path='/DP-Bau/notifies/:wp/:monat' render={(props) => <DPnotify {...props} userdata={this.props.userdata} selected_wp={this.props.selected_wp} offers={this.state.offers}
                                booked={this.state.booked} notify={this.state.notify} monat={this.props.monat} jahr={this.props.jahr} socket={this.props.socket} userlist={this.state.userdict} onDnD = {this.handleDnD} />}/>
                </Switch>
              </div>
              </div>
}
              </div>

    )


  }



}


export default DpbauContainer;
