import React, { Component } from 'react';
import CreatableSelect from 'react-select/lib/Creatable';
import './messagecenter.css'

export default class MessageReciever extends Component<*, State> {
constructor(props) {
  super(props)

  this.state = {
    userlist : []
  }
}

componentDidMount() {
  this.setState({userlist : this.props.userlist})
  this.setState({value : this.props.value})
}

componentDidUpdate(prevProps) {
  if (this.props.value !== prevProps.value) {
    this.setState({userlist : this.props.userlist})
    this.setState({value : this.props.value})
    this.handleChange(this.props.value, {action : 'set-value'});
  }
}

  handleChange = (newValue: any, actionMeta: any) => {
    console.group('Value Changed');
    console.log(newValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
    this.props.onRecChange(newValue);
  };

  render() {
    return (
      <CreatableSelect className="selectelement"
        isMulti
        placeholder="Empfänger wählen"
        value={this.state.value}
        onChange={this.handleChange}
        options={this.state.userlist}
      />
    );
  }
}
