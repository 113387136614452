import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import RListe from './rliste'
import InvoiceUpload from './invoiceupload'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion'

import 'react-accessible-accordion/dist/fancy-example.css';

class Rechnungen extends Component {
  constructor(props) {
    super(props)
    this.state={
      tab : 0,
      firstaccordion : true,
    }
  }

  componentDidMount () {
    let s = this.props.socket
    s.on('abrechnung full', data => {
      this.setState({unpaid:data.filter(d =>  !d.invoice).sort((a,b) => {return (a.monat+''+a.tag < b.monat+''+b.tag) ? -1 : 1})})
      this.setState({invoice:data.filter(d =>  d.invoice && !d.invoice_clear).sort((a,b) => {return (a.monat+''+a.tag < b.monat+''+b.tag) ? -1 : 1})})
      this.setState({paid:data.filter(d =>  d.invoice_clear).sort((a,b) => {return (a.monat+''+a.tag < b.monat+''+b.tag) ? -1 : 1})})

    })
    s.emit('abrechnung',{})
    //s.emit('abrechnung',{filter:{user:this.props.userdata.username}})
  }

  handleTabChange = (event, tab) => {
   this.setState({ tab });
  };

  closeaccordion = () => {
    this.setState({firstaccordion : false})
  }




  render () {
    const {wp} = this.props.userdata
    const {tab, unpaid, invoice, paid} = this.state
    return (

      <div>
      {wp.length > 0 &&
        <Tabs
          value={tab}
           onChange={this.handleTabChange}
           indicatorColor="primary"
           textColor="primary"
           centered
        >
        {wp.map((w,i) =>
          <Tab key={'tab'+i} label={w == 'NA_WGB' ? 'Wegberg' : 'LNA'}/>
        )}
        </Tabs>
      }
        <div>
        <Accordion>
             <AccordionItem expanded={this.state.firstaccordion}>
                 <AccordionItemTitle>
                     <h3>{'ohne Rechnung ('+ (unpaid && unpaid.filter(d => d.wp == wp[tab]).length)+')'}</h3>
                 </AccordionItemTitle>
                 {unpaid && <AccordionItemBody className="test">
                     <InvoiceUpload data={this.state.unpaid.filter(d => d.wp == wp[tab])} wp={wp[tab]}
                     socket={this.props.socket} userdata={this.props.userdata} closethis={this.closeaccordion}/>
                 </AccordionItemBody>
                }
             </AccordionItem>
             <AccordionItem>
                 <AccordionItemTitle>
                     <h3>{'Rechnung gestellt ('+ (invoice && invoice.filter(d => d.wp == wp[tab]).length)+')'}</h3>
                 </AccordionItemTitle>
              {invoice &&
                 <AccordionItemBody>
                      <RListe data={this.state.invoice.filter(d => d.wp == wp[tab])}/>
                 </AccordionItemBody>
               }
             </AccordionItem>
             <AccordionItem>
                 <AccordionItemTitle>
                     <h3>{'Abgerechnet ('+ (paid && paid.filter(d => d.wp == wp[tab]).length)+')'}</h3>
                 </AccordionItemTitle>
            {paid &&
                 <AccordionItemBody>
                      <RListe data={this.state.paid.filter(d => d.wp == wp[tab])}/>
                 </AccordionItemBody>
              }
             </AccordionItem>
         </Accordion>
         </div>

      </div>

    )
  }

}

export default Rechnungen
