import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../days/days.css';
import Shift from './shifts'
import DeleteIcon from '@material-ui/icons/Delete';
import { DropTarget } from 'react-dnd'

function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType()
  };
}


const dropzone = {
  drop(props, monitor, component) {
    let data = {'is': monitor.getItem().props.shift,'tag':monitor.getItem().props.tag,'type':props.type,'was':null}
    component.props.onDnD(data)
    //console.log('dz')
    //console.log(data)
    return {props};
  }
}

class DTDelPlace extends Component {
  constructor(props) {
    super(props)
  }


  render() {

  const boxstyle ={
      width: '30%',
      height : '40px',
    }

    const iconstyle = {
      fontSize : '40px'
    }



    const { isOver, canDrop, connectDropTarget } = this.props;
    return connectDropTarget(
      <div className="garbagefield" style={boxstyle}>
        <DeleteIcon style={iconstyle}/>
      </div>
    )
  }

}

export default DropTarget((props) => {return((Array.from(Array(31).keys()).map((n,i) => 'TD-'+(i+1))+','+Array.from(Array(31).keys()).map((n,i) => 'ND-'+(i+1))).split(','))},dropzone,collect)(DTDelPlace);
