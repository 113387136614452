import React, { Component } from 'react';
import './days.css';

class Preday extends Component {
  constructor(props) {
    super(props)
  }
render () {


    return (

      <div className={"pre_day "+'inner_'+this.props.weeks}></div>
    )
  }
}

export default Preday;
