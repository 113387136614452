import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { Link } from "react-router-dom";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail'
import StarIcon from '@material-ui/icons/Star';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DraftsIcon from '@material-ui/icons/Drafts';
import {isMobile} from 'react-device-detect';
import { Typography } from '@material-ui/core';

// const styles = {
//   list: {
//     width: 250,
//   },
//   fullList: {
//     width: 'auto',
//   },
// }

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 430,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
  },
});


const drawerWidth = 40;

class Menu extends React.Component {
  constructor(props) {
    super(props)
  this.state = {'open' : false}
}
  toggleDrawer = () => {
    this.props.onToggleMenu(!this.props.open);
  };

  componentWillReceiveProps () {
      this.setState({open : !this.props.open});
  }

  dbmonat = () => {
    let x = new Date()
    let m = x.getMonth()
    return ((m+1)<10 ? '0'+(m+1) : (m+1))+'.'+x.getFullYear()
  }

  firstwp = () => {
    if ('manage_wp' in this.props.userdata && this.props.userdata.manage_wp) {
      return this.props.userdata.manage_wp[0]
    }
    else {
      if ('wp' in this.props.userdata && this.props.userdata.wp)
        return this.props.userdata.wp[0]
      else
        return ''
    }
  }

  logout = () => {
    localStorage.removeItem('access_token')
    sessionStorage.removeItem('access_token')
    this.toggleDrawer()
    window.location.reload()
  }


  render() {
    const { classes, theme } = this.props;

    const sideList = (
      <div className={classes.list}>
      <List>
      <div>
      <ListItem button onClick={() => this.toggleDrawer()}>
      <ListItemIcon>
        <ChevronLeftIcon />
      </ListItemIcon>
      <ListItemText primary="Zurück" />
      </ListItem>
      <Divider />
      <Link to={"/calendar/"+this.dbmonat()} style={{ textDecoration: 'none' }}>
      <ListItem button onClick={() => this.toggleDrawer()}>
      <ListItemIcon>
        <StarIcon />
      </ListItemIcon>
       <ListItemText primary="Kalender" />
     </ListItem>
    </Link>
      <Link to={"/dienstplan/"+this.dbmonat()} style={{ textDecoration: 'none' }}>
        <ListItem button onClick={() => this.toggleDrawer()}>
        <ListItemIcon>
          <StarIcon />
        </ListItemIcon>
         <ListItemText primary="Dienstplan" />
       </ListItem>
      </Link>
      <Link to="/Rechnungen/" style={{ textDecoration: 'none' }}>
        <ListItem button onClick={() => this.toggleDrawer()}>
        <ListItemIcon>
          <StarIcon />
        </ListItemIcon>
         <ListItemText primary="Rechnungen" />
       </ListItem>
      </Link>
      <Link to="/Kontaktliste" style={{ textDecoration: 'none' }}>
        <ListItem button onClick={() => this.toggleDrawer()}>
        <ListItemIcon>
          <StarIcon />
        </ListItemIcon>
         <ListItemText primary="Kontaktliste" />
       </ListItem>
      </Link>
      <Divider/>
    <Link to="/Covid-Zertifikat" style={{ textDecoration: 'none' }}>
      <ListItem button onClick={() => this.toggleDrawer()}>
      <ListItemIcon>
        <StarIcon />
      </ListItemIcon>
       <ListItemText primary="Covid-Zertifikat" />
     </ListItem>
    </Link>
    <Link to="/Einstellungen" style={{ textDecoration: 'none' }}>
      <ListItem button onClick={() => this.toggleDrawer()}>
      <ListItemIcon>
        <StarIcon />
      </ListItemIcon>
       <ListItemText primary="Einstellungen" />
     </ListItem>
    </Link>
    <Link to={"/"} style={{ textDecoration: 'none' }}>
    <ListItem button onClick={() => this.logout()}>
    <ListItemIcon>
      <StarIcon />
    </ListItemIcon>
     <ListItemText primary="Abmelden" />
   </ListItem>
  </Link>
  <Divider />
  {this.props.ismanager &&
    <div>
     <Link to="/WPManage" style={{ textDecoration: 'none' }}>
     <ListItem button onClick={() => this.toggleDrawer()}>
     <ListItemIcon>
       <DraftsIcon />
     </ListItemIcon>
        <ListItemText primary="DP-Bau" />
    </ListItem>
    </Link>
    { (this.props.userdata.username == 'cdohmen' || this.props.userdata.username == 'PetersRD' || this.props.userdata.username == 'FiBu RDHS') &&
      <Link to="/Abrechnung" style={{ textDecoration: 'none' }}>
      <ListItem button onClick={() => this.toggleDrawer()}>
      <ListItemIcon>
        <DraftsIcon />
      </ListItemIcon>
         <ListItemText primary="Abrechnung" />
     </ListItem>
     </Link>
    }
    <Link to="/messagecenter" style={{ textDecoration: 'none' }}>
    <ListItem button onClick={() => this.toggleDrawer()}>
    <ListItemIcon>
      <MailIcon />
    </ListItemIcon>
       <ListItemText primary="Nachrichtencenter" />
   </ListItem>
   </Link>
   </div>
  }
    </div>
    </List>
    <Typography variant="caption">V {this.props.version}</Typography>
      </div>
    );

if (isMobile || true) {
    return (
      <div>
        <SwipeableDrawer
          open={this.props.open}
          onClose={this.toggleDrawer}
          onOpen={this.toggleDrawer}>
            {sideList}
        </SwipeableDrawer>
      </div>
    )
  } else {
    return (
      <div>
        <Drawer variant="permanent"
        classes={{ paper: classNames(classes.drawerPaper, !this.state.open && classes.toggleDrawer), }}
       open={this.state.open} >
            {sideList}
        </Drawer>
      </div>

    )

  }
  }
}

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Menu);
