import React from 'react';
import PropTypes from 'prop-types';
import { Route, Link, withRouter } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogContent from '@material-ui/core/DialogContent';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done'
import CancelIcon from '@material-ui/icons/Cancel'
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button'
import '../general.css'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import setUserPW from './setuserpw'


const swal = withReactContent(Swal)


const editstyle = {
  maxWidth : '80%'
}

const edithalfstyle = {
  maxWidth : '40%'
}

const donestyle = {
  color : '#00b600',
  fontSize : '37px'
}

const cancelstyle = {
  color : '#e10808',
  fontSize : '37px'
}

const paperstyle = {
  marginLeft : 'auto',
  marginRight :'auto',
  maxWidth: '90vw'
}

const confirmcancelstyle = {
  display: 'flex',
  float : 'right',
marginRight: '-20px'
}

const bottomright = {
  float: 'right',
  // position: 'absolute',
  // marginLeft : 'auto',
  // marginRight : '10px'
}


class AddUser extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      wp : []
    }

  }

  componentDidMount () {

}
  componentDidUpdate (prevProps) {

  }


  cancelAdd = () => {
    let s = this.props.socket
    s.emit('send snackbartext',{'text':'neuer Nutzer abgebrochen'})
    this.props.history.push('/Kontaktliste')
  }

  saveAdd = () => {
    if (this.state.first && this.state.last && this.state.tel && this.state.password && this.state.wp && this.state.email) {
      let s = this.props.socket
      s.emit('add user',{...this.state})
      this.props.history.push('/Kontaktliste')
    }
    else {
      swal.fire({
        text : 'Bitte keine Felder leer lassen',
        confirmButtonText: 'Okay'
      })
    }
  }





  confirmEdit = () => {
    let socket = this.props.socket
    console.log('start edit')
    if (this.props.userdata.name.first !== this.state.first || this.props.userdata.name.last !== this.state.last || this.props.userdata.tel !== this.state.tel || this.props.userdata.email !== this.state.email) {
      if (this.state.first &&  this.state.last  && this.state.tel  && this.state.email ) {
        socket.emit('admin update userdata',{username: this.state.username, first : this.state.first, last: this.state.last, tel:this.state.tel,email:this.state.email})
        this.cancelEdit()
    } else {
      console.log(this.state)
      swal.fire({
        text : 'Bitte keine Felder leer lassen',
        confirmButtonText: 'Okay'
      })
        this.cancelEdit()
    }
    }

  }

  handleGroupChange = (e,wp) => {
    let s = this.props.socket
    let wp_list = this.state.wp
    if (wp_list.indexOf(wp) == -1) {
      wp_list.push(wp)
    } else {
      wp_list = wp_list.filter(i => i !== wp)
    }
    this.setState({wp:wp_list})
    s.emit('change groups',{username: this.state.username,wp:wp_list})
    console.log(wp_list)
  }


  render () {
    console.log(this.props.userdata)
  return (
    <Paper>
      <DialogContent >
        <TextField style={editstyle}
           value = {this.state.username}
           label="Benutzername"
           onChange={(e) => this.setState({username:e.target.value})}
         />
         </DialogContent>
         <DialogContent >
         <TextField style={edithalfstyle}
         required
            value = {this.state.first}
            label="Vorname"
            onChange={(e) => this.setState({first:e.target.value})}

          />
          <TextField style={edithalfstyle}
          required
             value = {this.state.last}
             label="Nachname"
             onChange={(e) => this.setState({last:e.target.value})}
           />
          </DialogContent>
           <DialogContent>
           <TextField style={editstyle}
            required
              value = {this.state.tel}
              label="Telefonnummer"
                onChange = {(e) => this.setState({tel:e.target.value})}
            />
            </DialogContent>
            <DialogContent>

            <TextField style={editstyle}
                required
               value = {this.state.email}
               label="Email"
               onChange = {(e) => this.setState({email:e.target.value})}
             />
             </DialogContent>
             <DialogContent>
             <FormGroup row>
               {this.props.userdata.wp.map( (wp,i) => (
                 <FormControlLabel key={'wp_change_'+i}
                     control={
                      <Switch
                          checked={this.state.wp.indexOf(wp) !== -1}
                          onChange={e => this.handleGroupChange(e,wp)}
                          value={wp}/>}
                        label={wp}/>    )  )}
             </FormGroup>
             </DialogContent>
             <DialogContent >
               <TextField style={editstyle}
                  value = {this.state.password}
                  label="Passwort"
                  onChange={(e) => this.setState({password:e.target.value})}
                />
              </DialogContent>
              <DialogContent>
              <Button variant="contained" color="secondary" onClick={this.cancelAdd}>
                  Abbrechen
                </Button>
                <Button variant="contained" color="primary" onClick={this.saveAdd}>
                    Nutzer hinzufügen
                </Button>
              </DialogContent>
    </Paper>
  )}
}

AddUser.propTypes = {
  userdata: PropTypes.object.isRequired
};

export default withRouter(AddUser)
