import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import ax_ins from '../ajax';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
});

export default class Loginpopup extends React.Component {
	constructor(props){
	  super(props);

	  this.state={
    open :  false,
    tel : ''
	}
}


//componentDidUpdate () {
//  console.log('new props');
//  this.setState({open : this.props.open});
//}

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleClick(event){
      let data={
        tel : this.state.tel
      }
      ax_ins.post('resetrdhspw',data).then((response) => {
          this.props.pwreseted(true);
      }).catch((error) => {
        this.props.pwreseted(false);
      })
    }


  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
        >
          <DialogTitle id="form-dialog-title">Passwort zurücksetzen</DialogTitle>
          <DialogContent>
            <TextField
               label="Handynummer"
               onChange = {(e) => this.setState({tel:e.target.value})}
             />
            </DialogContent>
          <DialogActions>
             <Button variant="contained" color="primary" primary='true' onClick={(event) => this.handleClick(event)}>
             Absenden
             </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
