import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './days.css';

const cp = ['#0095ff54','#00c06645','#ffb93566','#ff353587']

const cf = ['#0095ffde','#00c066b8','#ffb935eb','#ff353587']


const colors = (c1,c2,c3) => {
  let fc = cp[c1]
  let sc = (c2 !== undefined ) ? cp[c2] : 'transparent'
  if (c3 !== undefined) {
    let tc = cp[c3]
    return "repeating-linear-gradient(-45deg,"+fc+","+ fc+" 6%,"+sc+" 10%,"+sc+" 14%, "+tc+" 16%,"+ tc+ " 20% )"
    }
  return "repeating-linear-gradient(-45deg,"+fc+","+ fc+" 5%,"+sc+" 7%,"+sc+" 12%,"+fc+" 14%)"

}

//'repeating-linear-gradient(-45deg,'colors_part+colors(ap.indexOf(keys[0]))+')'};

//'repeating-linear-gradient(-45deg,'+colors_part[ap.indexOf(keys[0])]+','+c1+' 50%,'+c2+' 50%, '+c2+')' };

const FT = {'2018':{'10':[3],'11':[1],
'12':[25,26]},'2019':{'01':[1],'04':[19,22],
'05':[1,30],'06':[10,20],'10':[3],'11':[1],'12':[25,26] } }

class Calday extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bg_td : {},
      bg_nd : {},
    }


  }

  //Object.keys(x).map((k,i) => {return 'key: '+k+' -> '+Object.keys(x[k]).map((s,j) => {return 'shift: '+s+' = '+x[k][s]}) })
//

  build_style_array = (shift) => {
    let co = this.props.current_offers
    let ap = this.props.allowed_wp
    let mc = this.props.mon_conf
    let str = {background : ''};
    if (this.props.current_offers) {
    if (('booked' in co && shift in co.booked) && !(mc[co.booked[shift]].shiftshidden)) {
      str = {
        background : cf[ap.indexOf(co.booked[shift])],
        border : '1px solid #ff000075',
      }
      return str
    }

    let keys = Object.keys(co).map((k,i) => {if ( ap.indexOf(k) !== -1 && shift in co[k] && co[k][shift]) return k }).filter(w => w)
    let c1,c2,c3;
    switch (keys.length) {
      case 0:
        break;
      case 1:
        str = {background : colors(ap.indexOf(keys[0]))};
        break;
      case 2:
        c1 = colors(ap.indexOf(keys[0]),0);
        c2 = colors(ap.indexOf(keys[1]),50);
        str = {background : colors(ap.indexOf(keys[0]),ap.indexOf(keys[1]))};
        //str = { background : 'repeating-linear-gradient(-45deg,'+cp[ap.indexOf(keys[0])]+','+c1+' 50%,'+cp[ap.indexOf(keys[1])]+' 50%, '+c2+')' };
          break;
      case 3:
        c1 = colors[ap.indexOf(keys[0])];
        c2 = colors[ap.indexOf(keys[1])];
        c3 = colors[ap.indexOf(keys[2])];
        str = { background : 'repeating-linear-gradient(-45deg,'+c1+','+c1+' 33%,'+c2+' 33%, '+c2+'66%,'+c3+' 66%,'+c3+')'};
        break;
      default:
          break;
      }
    }
      return str;
    }

  blockedstyle = (shift) => {
    let dp = this.props.dayplan
    let str = {background : ''};
    if (dp && dp[shift]) {
      switch (Object.keys(dp[shift]).length) {
        case 0:
          break;
        case 1:
          str = {background : 'rgba(200, 200, 200, 0.5)'}
          break;
        case 2:
          str = {background : 'rgba(170, 170, 170, 0.5)'}
          break;
        default:
          break;
      }
    }
    return str
  }




  componentDidMount () {
  //if ((this.props.current_offers) && (this.props.current_offers.TD)) {this.setState({TD : {background : '#0095ffb8'}})} else {this.setState({TD : {background : ''}})}
  //if ((this.props.current_offers) && (this.props.current_offers.ND)) {this.setState({ND : {background : '#0095ffb8'}})} else {this.setState({ND : {background : ''}})}
  if (this.props.current_offers) {
    // this.setState({bg_td : this.build_style_array('TD')})
    // this.setState({bg_nd : this.build_style_array('ND')})
  }
  }

  componentDidUpdate (prevProps) {
if (this.props !== prevProps) {
  //if ((this.props.current_offers) && (this.props.current_offers.TD)) {this.setState({TD : {background : '#0095ffb8'}})} else {this.setState({TD : {background : ''}})}
  //if ((this.props.current_offers) && (this.props.current_offers.ND)) {this.setState({ND : {background : '#0095ffb8'}})} else {this.setState({ND : {background : ''}})}
    this.setState({bg_td : this.build_style_array('TD')})
    this.setState({bg_nd : this.build_style_array('ND')})
    this.setState({bl_td : this.blockedstyle('TD')})
    this.setState({bl_nd : this.blockedstyle('ND')})
  }
  }

dayclicked = (event,tag) => {
  console.log('clicked in cal_day');
  this.props.onClicked(event,tag);
}


  caption = (shift) => {
      let result = []
      for (let i in this.props.current_offers) {
      if ( i !== 'TD' && i !== 'ND' && i !== 'booked' &&  shift in this.props.current_offers[i] &&  this.props.current_offers[i][shift] ) {
        let capt;
        if (i == 'LNA_HS')  capt = 'LNA'
        if (i == 'NA_WGB')  capt = 'WGB'
        result.push(<div key={i}>{capt}</div>)
      }
      }
      if( result.length > 1) return ''
      else return result
  }


  render() {
    const wd = new Date(parseInt(this.props.jahr,10),parseInt(this.props.monat,10)-1,this.props.tag-1).getDay()

    return (
        <div rel={this.props.tag} className={"cal_day "+(wd === 5 && 'wt_Sa')+' '+(wd === 6 && 'wt_So')+' '+'inner_'+this.props.weeks+' '+
        (this.props.jahr in FT && this.props.monat in FT[this.props.jahr]  && FT[this.props.jahr][this.props.monat].indexOf(this.props.tag) !== -1 && 'wt_FT') }
        onClick={(event) => this.dayclicked(event,this.props.tag)} >
        <div className="day_nr">{this.props.tag}</div>
          <div className={"dayshift"} style={this.state.bg_td.background !== "" ? this.state.bg_td : this.state.bl_td}><div className="offercapt">{this.caption('TD')}</div></div>
          <div className={"nightshift"} style={this.state.bg_nd.background !== "" ? this.state.bg_nd : this.state.bl_nd}><div className="offercapt">{this.caption('ND')}</div></div>
        </div>
    )
  }
}

Calday.propTypes = {
  allowed_wp: PropTypes.array.isRequired,
};

Calday.defaultProps = {
  //current_offers : {}
}

export default Calday;
