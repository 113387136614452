import React, { Component } from 'react';
import DTBookedShiftPlace from '../dndelements/dtshiftplace'
import DTOfferShiftPlace from '../dndelements/dtofferplace'
import './days.css';

class DnDday extends Component {
  constructor(props) {
    super(props)
    this.state={
      td : [],
      nd : [],
      offers : []
    }
  }

  onHandleDnD = (data) => {
    // bekommst is:{shift], fullname, user},tag, type(TD,ND, Offer), was: [{'shift,fullname,user'}] oder undefined
    console.log('data weiter')
    this.props.onDnD(data);
  }

  reloadDay = () => {


  }

  componentDidMount() {
    //kommt : tag, current_offers, current_booked, onDnd
    let cb = this.props.current_booked
    let co = this.props.current_offers
    this.setState({td : ('TD' in cb && cb['TD']) ? cb['TD'] : []})
    this.setState({nd : ('ND' in cb && cb['ND']) ? cb['ND'] : []})
    this.setState({offers : ('offer' in co)  ? co['offer'] : []})
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps){
      let cb = this.props.current_booked
      let co = this.props.current_offers
      this.setState({td : ('TD' in cb && cb['TD']) ? cb['TD'] : []})
      this.setState({nd : ('ND' in cb && cb['ND']) ? cb['ND'] : []})
      this.setState({offers : ('offer' in co)  ? co['offer'] : []})
    }
  }

  render() {

    const {weeks, maxoffers, innerscroll} = this.props

    const outerstyle = {
      height: 'calc(20px  * ( '+ maxoffers+' + 1 ) )',
    }

    const innerstyle = {

    }


    return (
      <div className={"dnd_day "+(innerscroll ? 'inner_'+weeks :'outer_'+weeks+'_') } style={innerscroll ? innerstyle : outerstyle}>
      <div className="day_nr">{this.props.tag}</div>
      <DTBookedShiftPlace type='TD' tag={this.props.tag} onDnD={this.onHandleDnD} highlightuser={this.props.highlightuser} current_booked={this.state.td} userlist={this.props.userlist}/>
      <DTBookedShiftPlace type='ND' tag={this.props.tag} onDnD={this.onHandleDnD} highlightuser={this.props.highlightuser} current_booked={this.state.nd} userlist={this.props.userlist}/>
      <DTOfferShiftPlace  innerscroll={this.props.innerscroll} type='Offer' tag={this.props.tag} onDnD={this.onHandleDnD} highlightuser={this.props.highlightuser} current_offers={this.state.offers} userlist={this.props.userlist}/>
      </div>
    )
  }


}

DnDday.defaultProps = {
  tag : 'test'
}

export default DnDday;
