import React, { Component } from 'react';
import './days.css';

class Preday extends Component {
  constructor(props) {
    super(props)
  }
render () {

  const outerscroll = {
    height: 'calc(20px  * ( '+ this.props.maxoffers+' + 1 ) )',
    minHeight : 'calc(100% / '+this.props.weeks+')',
  }

  const innerscroll = {
        height  : 'calc(100% / '+this.props.weeks+')'
  }

    return (

      <div style={this.props.innerscroll ? innerscroll : outerscroll} className="dnd_day"></div>
    )
  }
}

export default Preday;
