import * as React from 'react';
import { Route } from "react-router-dom";


const style = {
  width: '18vw',
  float: 'right',
  maxWidth : '230px',
 listStyle : 'none',
 paddingLeft : '10px',
 overflow : 'hidden',
 'height' : '80vh'

}

const userliststyle = {
  height: '70vh',
  overflow : 'auto'
}

const listtyle = {
  listStyle : 'none',
  paddingLeft : '0px'
}

const tblleft = {

}

const tblright = {

}

const listline = {

}



class StatSiteMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userlist : [],
    }
  }




componentDidMount () {
  const {monat,selected_wp,socket} = this.props
  socket.on(monat+' '+selected_wp+' month stat', data => {
    this.setState({userlist:data})
  })
  socket.emit('month stat',{wp:selected_wp,monat:monat})
  }

componentDidUpdate (prevProps) {
   if (this.props.monat !== prevProps.monat || this.props.selected_wp !== prevProps.selected_wp) {
     const {monat,selected_wp,socket} = this.props
     socket.off(prevProps.monat+' '+prevProps.selected_wp+' month stat')
     socket.on(monat+' '+selected_wp+' month stat', data => {
       this.setState({userlist:data})
     })
     socket.emit('month stat',{wp:selected_wp,monat:monat})
}
}







  render (){
      return  (
        <div className="statsitemenu" style={style}>
          <div className="userlist" style={userliststyle}>
          <ul style={listtyle}>
            {this.state.userlist && this.state.userlist.map((user,i) =>
              <li key={user.fullname} style={listline}><div onMouseOut={e => this.props.highlightuser(false)} onMouseOver={e => this.props.highlightuser(user.fullname)} style={{display: 'flex', justifyContent : 'space-between', width:'100%', background:'linear-gradient(90deg,green, #00800033 '+user.verh*100+'%, transparent)'}}><div style={tblleft}> {user.fullname}</div>
              <div style={tblright}>{'  '+user.dienste+' - '}{(user.wuensche < 10 ? '0': '')}{user.wuensche}</div></div></li>
            )}
          </ul>
          </div>
        </div>
      )
    }
}


export default StatSiteMenu;
