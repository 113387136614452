import React, { Component } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const tablecell = {
  paddingRight : "24px"
}


export default class RListe extends Component {
 constructor(props){
   super(props)
 }

  render () {
    return (
      <div>
      {this.props.data &&
      <Table>
              <TableHead>
                  <TableRow>
                      <TableCell style={tablecell}>Datum</TableCell>
                      <TableCell style={tablecell}>Dienst</TableCell>
                      <TableCell style={tablecell}>Rechnung</TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                {this.props.data.map((d,i) =>
                  <TableRow key={i+'bez'}>
                    <TableCell style={tablecell}>{d.tag+'.'+d.monat}</TableCell>
                    <TableCell style={tablecell}>{d.shift.substr(1)}</TableCell>
                    <TableCell style={tablecell}>{d.invoice_clear || d.invoice || ''}</TableCell>
                  </TableRow>
                )}
              </TableBody>
          </Table>
      }
      </div>
    )
  }

}
