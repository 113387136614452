import axios from 'axios';
//const url =  window.location.host === 'localhost:3000' ? "https://na.rdheinsberg.de/rest/" : ('https://'+window.location.host+'/rest/');

const url = 'https://na.rdheinsberg.de/rest/'
const ax_ins = axios.create({
  baseURL: url,
  headers : {'Content-Type': 'text/plain'}
});




const Response_Interceptor = ax_ins.interceptors.response.use(function (response) {
    // Do something with response data
    console.log(response);
    return response;
  }, function (error) {
    // Do something with response error
    return Promise.reject(error);

});

const Request_Interceptor = ax_ins.interceptors.request.use(function (config) {
    // Do something with response data
    console.log(config);
    return config;
  }, function (error) {
    // Do something with response error
    console.log(error);
    return Promise.reject(error);
});


ax_ins.interceptors.response.eject(Response_Interceptor);
ax_ins.interceptors.request.eject(Request_Interceptor);


export default ax_ins;
