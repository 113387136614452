import React, { Component } from 'react'
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button'
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography'
import FineUploaderTraditional from 'fine-uploader-wrappers'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import Gallery from 'react-fine-uploader'
import ClearIcon from '@material-ui/icons/Clear';
import Checkbox from '@material-ui/core/Checkbox'
import Dropzone from 'react-fine-uploader/dropzone'
import DeleteButton from 'react-fine-uploader/delete-button'
import Thumbnail from 'react-fine-uploader/thumbnail'
import ProgressBar from 'react-fine-uploader/progress-bar'
import FileInput from 'react-fine-uploader/file-input'
import Filename from 'react-fine-uploader/filename'
import Filesize from 'react-fine-uploader/filesize'
import TextField from '@material-ui/core/TextField';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


const swal = withReactContent(Swal)



const uploader = new FineUploaderTraditional({
    options: {
        cors: {
        //all requests are expected to be cross-domain requests
        expected: true,
      },
        chunking: {
            enabled: false
        },
        request: {
          customHeaders: {
              "Authorization": "Bearer " + localStorage.getItem('access_token') || sessionStorage.getItem('access_token')
          },
            endpoint: 'https://na.rdhs.eu/rest/upload_rechnung'
        },
        retry: {
            enableAuto: false
        }
    }
})

const tablecell = {
  paddingRight : "24px"
}

const tablecell_cb = {

}

function getSteps() {
  return ['Dienste auswählen', 'Rechnung hochladen', 'Bemerkungen und Absenden'];
}

const delbuttonstyle = {
  background: 'white',
  borderWidth: '0px',
  verticalAlign :'middle',
}

const progresssyle =  {
  height: '6px',
  background :'blue'
}

const stepper = {
  padding: '0px',
}

const textboxstyle = {
  margin : '10px'
}


export default class InvoiceUpload extends Component {
 constructor(props){
   super(props)
   this.state={
     activeStep : 0,
     selectedDays : [],
     allSelected : false,
     submittedFile : false
   }
 }

 componentDidMount () {
   uploader.on('complete', (id,name,json) => {
     console.log(id,name,json)
     this.setState({submittedFile : json})
   })
 }

 componentDidUpdate (prevProps) {
  if (prevProps !== this.props) {

  }
 }

 handleNext = () => {
   const steps = getSteps();
   const {activeStep, submittedFile, selectedDays, comment} = this.state
   if (activeStep !== steps.length - 1) {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }));
  } else {
    const {data, socket, userdata, wp} = this.props
    console.log('sende ab')
    let query = []
    let toserver = {}
    for (var i = 0; i < selectedDays.length; i++) {
      const ds = data[selectedDays[i]]
      query.push({tag: ds.tag, monat:ds.monat, wp: ds.wp, shifts: ds.shift, fullname:ds.fullname})
    }
    toserver = {file:submittedFile, dienste: query, comment : comment, wp:wp, user: userdata.username}
    console.log(toserver)
    let triggerclose = this.props.closethis
    swal.fire({
      text : 'Rechnung  absenden?',
      confirmButtonText: 'Ja',
      showCancelButton: true,
    }).then((data) => {
      console.log(data)
      if ('value' in data && data.value == true){
      socket.emit('upload invoice',toserver)
      swal.fire({
           text : 'Rechnung gesendet',
           confirmButtonText: 'Okay',
         })
      triggerclose(true)
      }});
  }
  }

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  handleDelFile = () => {
    if (this.state.submittedFile){
    let s = this.props.socket
    s.emit('delfile',{fileid : this.state.submittedFile.id})
  }
  this.setState({submittedFile : false})
  }

  handleAddDay = (i) => {
    let tmp = this.state.selectedDays
    if (tmp.indexOf(i) == -1) {
      tmp.push(i)
    } else {
      tmp = tmp.filter(w => w !== i)
    }
    this.setState({selectedDays : tmp})
    this.setState({allSelected : false})
  }

  handleAddAll = (d) => {
    if (this.state.allSelected) {
      this.setState({selectedDays : []})
    }
    else {
      let tmp = Array.apply(null, {length: this.props.data.length}).map(Number.call, Number)
      this.setState({selectedDays : tmp})
    }
    this.setState({allSelected : !this.state.allSelected})
    console.log('addall')
  }

  getStepContent = (step) => {
    const {data} = this.props
    const {selectedDays, allSelected, submittedFile} = this.state
    switch (step) {
      case 0:
        return (
          <div>
          {data &&
          <Table>
                  <TableHead>
                      <TableRow>
                          <TableCell padding="checkbox" style={tablecell_cb} onClick={e => this.handleAddAll()}><Checkbox checked={allSelected}/></TableCell>
                          <TableCell padding="checkbox" style={tablecell_cb}>Datum</TableCell>
                          <TableCell padding="checkbox" style={tablecell_cb}>Dienst</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((d,i) =>
                      <TableRow key={i+'or'}  onClick={e => this.handleAddDay(i)}>
                        <TableCell padding="checkbox" style={tablecell_cb}><Checkbox checked={selectedDays.indexOf(i) !== -1 || allSelected}/></TableCell>
                        <TableCell padding="checkbox" style={tablecell_cb}>{d.tag+'.'+d.monat}</TableCell>
                        <TableCell padding="checkbox" style={tablecell_cb}>{d.shift.substr(1)}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
              </Table>
          }
          </div>

        );
      case 1:
        return (
          <div>
          <ProgressBar style={progresssyle} uploader={uploader}/>
          <Dropzone uploader={uploader}>
          {!submittedFile && <FileInput uploader={uploader }>
              <Button variant="contained" style={{margin: "5px"}}>Rechnung hochladen</Button>
          </FileInput> }
            {submittedFile &&
              <div >
                 {submittedFile.filename}  <Button onClick={this.handleDelFile}><ClearIcon/></Button>
              </div>
            }
            </Dropzone>
            </div>
        );
      case 2:
        return (
            <div style={textboxstyle}>
            <TextField
                fullWidth
               label="Bemerkungen"
               multiline
               value = {this.state.comment}
               onChange = {(e) => this.setState({'comment':e.target.value})}
             />
             <br/>
            </div>
        );
      default:
        return 'Fehler';
    }
  }

  render () {
    const steps = getSteps();
    const { activeStep, selectedDays,submittedFile } = this.state;


    return (
      <div >
      <Stepper activeStep={activeStep} orientation="vertical" style={stepper}>
        {steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <div>{this.getStepContent(index)}</div>
                <div >
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={this.handleBack}

                    >
                      zurück
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled = {(activeStep == 0 && selectedDays.length == 0) || (activeStep == 1 && !submittedFile)}
                      onClick={this.handleNext}

                    >
                      {activeStep === steps.length - 1 ? 'Absenden' : 'weiter'}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </div>
    )
  }
}
