import * as React from 'react';
import { Route } from "react-router-dom";
import Shift from './shifts'
import DTDelPlace from './dtdelplace'


const style = {
  width: '18vw',
  float: 'left',
  maxWidth : '230px',
 listStyle : 'none',
 paddingLeft : '10px',
 overflow : 'hidden',
 'height' : '80vh'

}

const userliststyle = {
  height: '70vh',
  overflow : 'auto'
}

const listtyle = {
  listStyle : 'none',
  paddingLeft : '0px'
}



class DnDSiteMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userlist : [],
      fulllist : [],
      items : [],
      search :'',
    }
  }

  handleDnD = (data) => {
    //kommt tag,user, wohin gezogen
    //console.log(data)
    this.props.onDnD(data)
  }

  filterList (event) {
    let updatedList = this.state.fulllist;
    if (event) { this.setState({search : event.target.value })}
    console.log('start filter')
    console.log(this.state.search)
    //this.setState({search : event.target.value})
    if (!this.state.search || this.state.search == '') {
      console.log('not filtering')
      this.setState({userlist : this.state.fulllist })
    } else {
    updatedList = updatedList.filter(item => {
      return item.name.last.toLowerCase().search(event.target.value.toLowerCase()) !== -1 || item.name.first.toLowerCase().search(event.target.value.toLowerCase()) !== -1;
    });
    this.setState({userlist: updatedList});}
    //this.setState({search : event.target.value})
  }


componentDidMount () {
this.setState({fulllist : this.props.userlist})
this.setState({userlist : this.props.userlist})
  }

componentDidUpdate (prevProps) {
   if (this.props.userlist !== prevProps.userlist) {
this.setState({fulllist : this.props.userlist})
this.setState({userlist : this.props.userlist})
this.setState({search: ''})
}
}


  render (){
      return  (
        <div className="dndsitemenu" style={style}>
        <DTDelPlace onDnD={this.handleDnD} type='deldrop'/>
        <div className="filter-list">
          <input type="search" placeholder="Search" onChange={this.filterList.bind(this)}/>
          <hr/>
          <div className="userlist" style={userliststyle}>
          <ul style={listtyle}>
            {this.state.userlist && this.state.userlist.map((user,i) =>
              <li key={user.username}>   <Shift key={i} loc='extern' listview={true} twin={false} tag={''} cert={user.cert[0]}
             type='extern' shift={{fullname: user.name.last+', '+user.name.first, user:user.username,shift:'extern'}}/>
              </li>
            )}
            {!this.state.userlist.length  &&
              <li key={this.state.search}>   <Shift loc='extern' listview={true} twin={false} tag={''} 
             type='extern' shift={{fullname: this.state.search, user:'extern', shift:'extern'}}/>
              </li>
            }
          </ul>
          </div>
        </div>
</div>
      )
}
}


export default DnDSiteMenu;
