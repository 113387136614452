import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../days/days.css';
import Shift from './shifts'
import { DropTarget } from 'react-dnd'

function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType()
  };
}


const dropzone = {
  drop(props, monitor, component) {
    let data = {'is': monitor.getItem().props.shift,'tag':props.tag,'type':props.type,'was':props.current_booked}
    component.props.onDnD(data)
    console.log('dz')
    console.log(data)
    return {props};
  }
}

class DTOfferShiftPlace extends Component {
  constructor(props) {
    super(props)
  }

  handleDblClck = (shift) => {
    let data = {is: shift, tag:this.props.tag,type:this.props.type, was: 'unknown'}
    console.log(data)
    this.props.onDnD(data)
    //component.props.onDnD(data)

  }

  render() {
    const { isOver, canDrop, connectDropTarget, innerscroll } = this.props;


    const co = this.props.current_offers;
      co.sort(function(a,b) {return (a.shift > b.shift) ? 1 : ((b.shift > a.shift) ? -1 : 0);} );
      if (!(this.props.listview)) co.sort(function(a,b) {return (a.user > b.user) ? 1 : ((b.user > a.user) ? -1 : 0);} );
    const l = co.length
    function istwin (d,j){
      return (j>0 && d[j-1]['user'] !== 'extern' && d[j-1]['user'] === d[j]['user'])  //|| (j<l-1 && d[j+1]['user'] == d[j]['user'])
    }
    return connectDropTarget(
      <div className={"dtoffer "+(innerscroll ? 'inners ' : 'outers ') +this.props.listview}
          style={this.props.listview && {height: 'calc( 15px *'+l+')' }}>
          {co.map((o,i) => <Shift key={i} loc='Offer' cert={this.props.userlist[o.user]} highlightuser={this.props.highlightuser} listview={this.props.listview} twin={istwin(co,i)} tag={this.props.tag}
          type={o.shift} onDblClck={this.handleDblClck}  shift={o}/>) }
          &nbsp;
      </div>
    )
  }

}

export default DropTarget((props) => {return ['TD-'+props.tag,'ND-'+props.tag]},dropzone,collect)(DTOfferShiftPlace);
