import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Preday from '../days/pre_day_classic';
import Calday from '../days/cal_day';
import OfferPopup from '../days/offer';
import odiff from "odiff";
import update from 'immutability-helper';
import '../calendar.css';


const wota = ['Mo','Di','Mi','Do','Fr','Sa','So']
const monate = ['Jan','Feb','März','April','Mai','Juni','Juli','Aug','Sept','Okt','Nov','Dez']



class Calendar extends Component {
  //aus parent: selected_user, userdata, monat, jahr, socket
  //holt vom server: current_offers von selected_user
  //an Calday: current_offers[tag], tag
    // zurück offer_day
  //an OfferPopup: current_offers[offer_day], allowed_wp,
    // zurück wp -> shift -> true/false

  constructor(props) {
    super(props)

    //let datum = this.props.datum;
    var datum = '01.01.2022';
    this.state = {
      charge : {},
      cur_user : {},
      response : false,
      show_offer_popoup : false,
      offer_day : 0,
      allowed_wp : [],
      manage_wp : [],
      fullplan : {},
      perfectheight : false,

    }
}

  firstdayinmonth = () => {    return new Date(parseInt(this.props.jahr,10),parseInt(this.props.monat,10)-1,0).getDay()  }
  daysinmonth = () => {    return new Date(parseInt(this.props.jahr,10),parseInt(this.props.monat,10),0).getDate()  }
  weekday = (day) => {    return new Date(parseInt(this.props.jahr,10),parseInt(this.props.monat,10)-1, day-1).getDay()  }
  dbmonat= () => {    return this.props.monat+'.'+this.props.jahr  }
  weeks = () => { return Math.ceil((this.firstdayinmonth()+this.daysinmonth())/7)}

  handleOffer = (newshifts,comment) => {
      let oldshifts = this.state.cur_user[this.state.offer_day]
      if (oldshifts !== newshifts) {
        var socket = this.props.socket;
        let main = {tag : this.state.offer_day,monat:this.dbmonat(),userid:this.props.selected_user,shifts : newshifts,comment : comment }
        socket.emit('offer shift',main);
        if ( socket._callbacks['offer shift response'] == undefined ) {
            socket.on('offer shift response', data => { this.setState(data) });
          }
        }
       this.setState({show_offer_popoup : false});
  }


  cancelOffer = () => {    this.setState({show_offer_popoup : false});  }

  dayclicked = (event,tag) => {
    this.setState({offer_day : tag});
    this.setState({show_offer_popoup : true});
  }

  componentDidMount () {
      console.log(this.props)
      var socket = this.props.socket;
      console.log('first mount')
      let h = this.div.getBoundingClientRect().top
      console.log(this.div.getBoundingClientRect())
      this.setState({perfectheight : 'calc(100vh - '+(h+15)+'px)'})
      // if ('onGetPerfectHeight' in this.props && !this.props.gotheight) {
      //   this.props.onGetPerfectHeight(this.div.getBoundingClientRect().height)
      // }
      socket.on('calendar month response', data => { 
        this.setState({...data})});
      socket.on('mconf broadcast', data => {
        let monat = data['monat']
        let wp = data['wp']
        if (wp in this.state.mon_conf && monat == this.state.mon_conf[wp].monat ) {
        let mconf = update(this.state.mon_conf,
         {[wp]: {'$set' : data} })
         this.setState({mon_conf : mconf})
       }
      })
      socket.emit('calendar month',{monat : this.dbmonat(), userid : this.props.selected_user });
      console.log('####,',this.props.userdata.cert)
      if (this.props.userdata.cert[0] == true){
            this.setState({allowed_wp : this.props.userdata.wp})
      }
      else {
        this.setState({allowed_wp : []})
      }
    }

  componentDidUpdate (prevProps) {
    if (this.props !== prevProps)  {
        let h = this.div.getBoundingClientRect().top
        console.log(this.div.getBoundingClientRect().height)
        if ('onGetPerfectHeight' in this.props &&( !this.props.gotheight || this.props.monat !== prevProps.monat)) {
        this.setState({perfectheight : 'calc(100vh - '+(h+15)+'px)'})
        this.props.onGetPerfectHeight(this.div.getBoundingClientRect())
      }
      this.setState({trans : this.props.trans})
      var socket = this.props.socket;
      if (prevProps.monat !== this.props.monat || prevProps.jahr !== this.props.jahr || prevProps.selected_user !== this.props.selected_user) {
        socket.emit('calendar month',{monat : this.dbmonat(), userid : this.props.selected_user });
      }
      // if (this.props.userdata.cert !== prevProps.userdata.cert) {
      //   console.log('certstatus changed')
      //   if (this.props.userdata.cert[0] == true){
      //     this.setState({allowed_wp : this.props.userdata.wp})
      //   }
      //   else {
      //     this.setState({allowed_wp : []})
      //   }
      // }
     }
  }

  // calcHeight = (node) => {
  //   if (node && this.state.perfectheight == false) {
  //       console.log("calcHeight", node.getBoundingClientRect());
  //       let h = node.getBoundingClientRect().top
  //       this.setState({perfectheight : 'calc( 100vh - '+(h+15)+'px)'})
  //       this.props.onGetPerfectHeight(node.getBoundingClientRect.height)
  //     }
  // }

  componentWillUnmount () {
    var socket = this.props.socket;
    socket.off('offer shift response')
    socket.off('calendar month response')
    socket.off('mconf broadcast')
  }



  render() {
    var plain = [];
    var day = [];


      for(var i=0; i < this.firstdayinmonth();i++) {
        plain.push(<Preday weeks={this.weeks()} key={i}/>);
      }
      for(i=0; i<this.daysinmonth();i++) {
        day.push(<Calday tag={i+1} key={i} weeks={this.weeks()} mon_conf={this.state.mon_conf}
        allowed_wp={this.state.allowed_wp} monat={this.props.monat} jahr={this.props.jahr}  dayplan={this.state.fullplan[i+1]}
        weekday={this.weekday(i+1)} current_offers={this.state.cur_user[i+1]} onClicked={this.dayclicked} /> );
      }
    //  this.get_month_dp();

//    });
  // const perfectheight = () => {
  //   height : this.state.perfectheight
  // }


    return (

      <div className="Calendar">
        <div className="cal_head">
        {wota.map((wot,index) => <div className="wota" key={index}>{wot}</div>)}
        </div>
      <div className="cal_body" ref={ div => { this.div = div } } style={{height : this.state.perfectheight}}>
      {plain}
      {day}
      </div>
    {this.state.allowed_wp != [] &&
      <OfferPopup open={this.state.show_offer_popoup} current_set={this.state.fullplan[this.state.offer_day] || {}} datum={this.state.offer_day+'.'+this.dbmonat()} offer_day ={this.state.offer_day}
        current_offers={this.state.cur_user[this.state.offer_day] || {} } wt={wota[this.weekday(this.state.offer_day)]} cert={this.props.userdata.cert}
        allowed_wp={this.state.allowed_wp} mon_conf={this.state.mon_conf} onOffer={this.handleOffer} onCancel={this.cancelOffer}/>
    }
      </div>


    );
  }
}

Calendar.propTypes = {
  userdata: PropTypes.object.isRequired,
  socket: PropTypes.object.isRequired
};



export default Calendar;
