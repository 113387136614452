import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from './menu'


const styles = {
  root: {
    flexGrow: 1,
  },
  flex: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

class Appbar extends Component {
  constructor(props) {
    super(props)
    this.state = {open_menu: false}
  }

 toggleMenu = () => {
   this.setState({open_menu : !this.state.open_menu})
 }

  render () {
  return (
    <div>
      <AppBar position="static" >
        <Toolbar>
        <IconButton onClick={() => this.toggleMenu()} color="inherit" aria-label="Menu">
          <MenuIcon />
        </IconButton>
          <Typography variant="title" color="inherit" >
            NA-Plan 
          </Typography>
        </Toolbar>
      </AppBar>
    <Menu open={this.state.open_menu} userdata={this.props.userdata} ismanager={this.props.ismanager} version={this.props.version} onToggleMenu={this.toggleMenu}/>
    </div>
  );
}
}



export default withStyles(styles)(Appbar);
