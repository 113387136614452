import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Preday from '../days/pre_day';
import Calday from '../days/cal_day';
import DnDday from '../days/dnd_day';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'
import DTBookedShiftPlace from '../dndelements/dtshiftplace'
import DTOfferShiftPlace from '../dndelements/dtofferplace'
import html2canvas from 'html2canvas';
import JSpdf from 'jspdf';
import '../calendar.css';

const wota = ['Mo','Di','Mi','Do','Fr','Sa','So']
const monate = ['Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember']

const FT = {
  '2018':{'10':[3],'11':[1],'12':[25,26]},
  '2019':{'01':[1],'04':[19,22],'05':[1,30],'06':[10,20],'10':[3],'11':[1],'12':[25,26] },
'2021':{'01':[1],'04':[4,5],'05':[1,13,23,24],'06':[3],'10':[3],'11':[1],'12':[25,26] },
 '2022':{'11':[1],'12':[25,26]},
'2023': {'01':[1],'04':[7,10],'05':[1,18,29],'06':[8],'10':[3],'11':[1],'12':[25,26]},
'2024' : {'01':[1],'04':[7,10],'05':[1,18,29],'06':[8],'10':[3],'11':[1],'12':[25,26]},
}

const style = {
  marginRight :'auto',
  marginLeft : 'auto',
  overflowX: 'hidden'

}

const rstyle = {
  height : '20px'
}


class Dienstplan extends Component {
  //aus parent: selected_user, userdata, monat, jahr, socket
  //holt vom server: current_offers von selected_user
  //an Calday: current_offers[tag], tag
    // zurück offer_day
  //an OfferPopup: current_offers[offer_day], allowed_wp,
    // zurück wp -> shift -> true/false

  constructor(props) {
    super(props)

    //let datum = this.props.datum;
    var datum = '01.09.2020';
    this.state = {
      charge : {},
      response : false,
      booked : {},

    }
}

  firstdayinmonth = () => {    return new Date(this.props.jahr,parseInt(this.props.monat,10)-1,0).getDay()  }
  daysinmonth = () => {    return new Date(this.props.jahr,parseInt(this.props.monat,10),0).getDate()  }
  weekday = (day) => {    return new Date(this.props.jahr,parseInt(this.props.monat,10)-1, day-1).getDay()  }
  dbmonat= () => {    return this.props.monat+'.'+this.props.jahr  }
  ufn = () => {return this.props.userdata.name.last+', '+this.props.userdata.name.first}
  stand = (full) => {
    let x = new Date()
    if (full) {return x.getDate()+'.'+(x.getMonth()+1)+'.'+x.getFullYear()+'_'+x.getHours()+'-'+x.getMinutes()}
    else {return x.getDate()+'.'+(x.getMonth()+1)+'.'+x.getFullYear()}
  }


  componentDidMount () {
    if (this.props.userdata.wp.length > 1) this.props.togglewpmultiselect(true)
    if (this.props.socket) {
      let socket = this.props.socket
      socket.emit('dp for user',{monat:this.dbmonat(),'wp':this.props.selected_wp})
      if ( socket._callbacks['dp response'] == undefined ) {
          socket.on('dp response', data => { this.setState(data) });
        }
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props !== prevProps) {
      let socket = this.props.socket
      socket.emit('dp for user',{monat:this.dbmonat(),'wp':this.props.selected_wp})
      if ( socket._callbacks['dp response'] == undefined ) {
          socket.on('dp response', data => { this.setState(data) });
        }
      }
      }


  componentWillUnmount() {
    this.props.togglewpmultiselect(false)
  }

  printDocument=() => {
    const input = document.getElementById('printview');
    html2canvas(input,{scale : 2})
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new JSpdf();
        pdf.addImage(imgData, 'PNG', 30, 10, 180, 250); ;
        // pdf.output('dataurlnewwindow');
        pdf.save('dp_'+this.dbmonat()+'_'+this.props.selected_wp+'_'+this.stand(true)+'.pdf');
      })
    ;
  }

  render() {
    var plain = [];
    var day = [];

    const head1 = {
      textAlign : 'center'
    }

    const head2 = {
      textAlign : 'center',
      fontSize : '75%'
    }

    const isft = (tag) => {
      if (this.props.jahr in FT && this.props.monat in FT[this.props.jahr]  && FT[this.props.jahr][this.props.monat].indexOf(tag) !== -1 ) {
        return 'wt_FT'
    }
      else {
        return ''
      }
    }

    if (this.state.mon_conf && !this.state.mon_conf.shiftshidden) {
     for (let i=0; i<this.daysinmonth();i++) {
       let td = i+1 in this.state.booked && this.state.booked[i+1] && 'TD' in this.state.booked[i+1]
        && this.state.booked[i+1]['TD'][0] && 'fullname' in this.state.booked[i+1]['TD'][0]? this.state.booked[i+1]['TD'][0].fullname : ''
        let nd = i+1 in this.state.booked && this.state.booked[i+1] && 'ND' in this.state.booked[i+1]
         && this.state.booked[i+1]['ND'][0] && 'fullname' in this.state.booked[i+1]['ND'][0] ? this.state.booked[i+1]['ND'][0].fullname : ''

        day.push(
            <TableRow key={i} style={rstyle} className={'wt_'+wota[this.weekday(i+1)]+' '+isft(i+1) }>
              <TableCell component="th" scope="row">
                {(i+1)+'.'+this.props.monat+', '+wota[this.weekday(i+1)]}
              </TableCell>
              <TableCell className={this.ufn() == td ? 'printviewownshift':''}>{td}</TableCell>
              <TableCell className={this.ufn() == nd ? 'printviewownshift':''}>{nd}</TableCell>
            </TableRow>
            )
        }
      }


    return (
      <div id="printview" className="Liste" style={style}>
      <Paper>
      <Typography variant='headline' component='h3' style={head1} onClick={() => this.printDocument()}>
        {monate[parseInt(this.props.monat,10)-1] +' '+this.props.jahr}
      </Typography>
      <Typography variant='headline' component='h5' style={head2}>
        {'Stand: '+this.stand(false)}
      </Typography>
      <Table padding="dense">
        <TableHead>
          <TableRow>
            <TableCell>Datum</TableCell>
            <TableCell>Tagdienst</TableCell>
            <TableCell>Nachtdienst</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {day}
        </TableBody>
      </Table>
    </Paper>
      </div>
    );
  }
}

Dienstplan.propTypes = {
  userdata: PropTypes.object.isRequired
};



export default Dienstplan;
