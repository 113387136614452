import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PrintIcon from '@material-ui/icons/Print';
import ListIcon from '@material-ui/icons/List';
import Typography from '@material-ui/core/Typography'
import DTBookedShiftPlace from '../dndelements/dtshiftplace'
import DTOfferShiftPlace from '../dndelements/dtofferplace'
import html2canvas from 'html2canvas';
import { Link } from "react-router-dom";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import update from 'immutability-helper';
import JSpdf from 'jspdf';
import '../calendar.css';
import '../general.css'

const wota = ['Mo','Di','Mi','Do','Fr','Sa','So']
const monate = ['Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember']


const style = {
  marginRight :'auto',
  marginLeft : 'auto',
  width: '40vw',
}

const paddingDense = {
  padding: '5px 5px 5px 5px',
}

const paddingnorm = {
  paddingLeft: '65px',
}

const rstyle = {
  height : '20px'
}

const viewmod = {
  //display : 'flex'
}

const fgstyle = {
  textSize : '70%'
}

const paddingLast = {
  paddingLeft : '20px'
}


class WPManage extends Component {

  constructor(props) {
    super(props)

    //let datum = this.props.datum;

    this.state = {
      data : {},
      wp : ''
    }
}


  componentDidMount () {
  if (this.props.socket) {
    this.setState({wp : this.props.selected_wp})
    let socket = this.props.socket
    socket.emit('wp info',{wp:this.props.selected_wp})
    socket.once('wp data '+this.props.selected_wp, (data) => {
      this.setState({data})
    })
    socket.on('mconf broadcast', data => {
      console.log(data.wp,this.props.selected_wp)
      if (data.wp == this.props.selected_wp) {
        console.log(data)
        let monat = data['monat']
        const state = update(this.state.data,
        {'months': {[monat]: {'config' : {'$set' : data }}}}
         )
        console.log(state)
        //state.months[data['monat']].config = data
        this.setState({data : state})
    }
    })
  }
  }

  componentDidUpdate (prevProps) {
    if (this.props !== prevProps) {
      if (this.props.socket) {
        this.setState({wp : this.props.selected_wp})
        let socket = this.props.socket
        if ( socket._callbacks['wp data'] == undefined ) {
          socket.emit('wp info',{wp:this.state.wp})
          socket.once('wp data '+this.props.selected_wp, (data) => {
            this.setState({data})
          })
        }
      if ( socket._callbacks['mconf broadcast'] == undefined ) {
        socket.on('mconf broadcast', data => {
          console.log(data.wp,this.props.selected_wp)
          if (data.wp == this.props.selected_wp) {
            console.log(data)
            let monat = data['monat']
            const state = update(this.state.data,
            {'months': {[monat]: {'config' : {'$set' : data }}}}
             )
            console.log(state)
            //state.months[data['monat']].config = data
            this.setState({data : state})
        }
        })
    }}
    }
  }

  componentWillUnmount () {
    this.props.socket.off('wp data')
    this.props.socket.off('mconf broadcast')
  }

  handleChange = (e,m) => {

    let socket = this.props.socket
    let data = this.state.data.months[m].config
    data[e.target.value] = e.target.checked
    console.log(data)
    socket.emit('change month config',data)
  }


  render() {
    var plain = [];
    var day = [];

    const head1 = {
      textAlign : 'center'
    }

    const head2 = {
      textAlign : 'center',
      fontSize : '75%'
    }

      let data = []


        let d = this.state.data.months
        let keys = 'months' in this.state.data && Object.keys(this.state.data.months) || []
        keys.sort((a,b) => {return a.split('.')[1]+a.split('.')[0] > b.split('.')[1]+b.split('.')[0]})
        for(let j in  keys) {
        let k = keys[j]
          let tmp = (
          <TableRow key={k.split('.')[1]+k.split('.')[0]}>
            <TableCell style={paddingDense}>
              {k}
            </TableCell>
            <TableCell style={paddingDense}>
          <FormGroup style={fgstyle}>
          <FormControlLabel  control={
              <Switch checked={this.state.data.months[k].config.offersblocked}  onChange={(e) => this.handleChange(e,k)}  value="offersblocked"  />
            }  label="Wünsche gesperrt" />
          <FormControlLabel   control={
              <Switch  checked={this.state.data.months[k].config.shiftshidden}  onChange={(e) => this.handleChange(e,k)}  value="shiftshidden" />
            }  label="Dienste versteckt" />
          </FormGroup>
            </TableCell>
            <TableCell style={paddingDense}>
              {new Date(parseInt(k.substr(3)),parseInt(k.substr(0,2)),0).getDate()*2 - parseInt(d[k].booked)}
            </TableCell>
            <TableCell style={paddingDense}>
              {d[k].offers}
            </TableCell>
            <TableCell style={paddingDense}>
              {d[k]['unique_na']}
            </TableCell>
            <TableCell style={paddingDense}>
            <div style={viewmod}>
                <Link to={"/DP-Bau/calendar/"+this.state.wp+'/'+k} style={{ textDecoration: 'none', color : 'black' }}>
                  <div className="calenderview" >
                    <CalendarTodayIcon/>
                  </div>
                </Link>
                <Link to={"/DP-Bau/list/"+this.state.wp+'/'+k} style={{ textDecoration: 'none', color : 'black' }}>
                  <div className="listview">
                    <ListIcon/>
                  </div>
                </Link>
                <Link to={"/DP-Bau/print/"+this.state.wp+'/'+k} style={{ textDecoration: 'none', color : 'black' }}>
                  <div className="printview">
                    <PrintIcon/>
                  </div>
                </Link>
            </div>
            </TableCell>
          </TableRow>
        )
        data.push(tmp)
      }




    return (
<div>

            <Typography variant="title" gutterBottom>
      {'wp' in this.state.data  && this.state.data.wp.bez}
</Typography>

      <Table padding="dense" style={style}>
        <TableHead>
          <TableRow>
            <TableCell style={paddingDense}>Monat</TableCell>
            <TableCell style={paddingnorm}></TableCell>
            <TableCell style={paddingDense}>offene D.</TableCell>
            <TableCell style={paddingDense}>DW</TableCell>
            <TableCell style={paddingDense}>von x NA</TableCell>
            <TableCell style={paddingLast}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {data}
        </TableBody>
      </Table>

</div>
    );
  }
}

WPManage.propTypes = {
  userdata: PropTypes.object.isRequired
};



export default WPManage;
