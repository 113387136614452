import React, { Component } from 'react'
import FineUploaderTraditional from 'fine-uploader-wrappers'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import Gallery from 'react-fine-uploader'
import ClearIcon from '@material-ui/icons/Clear';

import Dropzone from 'react-fine-uploader/dropzone'
import DeleteButton from 'react-fine-uploader/delete-button'
import Thumbnail from 'react-fine-uploader/thumbnail'
import ProgressBar from 'react-fine-uploader/progress-bar'
import FileInput from 'react-fine-uploader/file-input'
import Filename from 'react-fine-uploader/filename'
import Filesize from 'react-fine-uploader/filesize'
import './messagecenter.css'

//import 'react-fine-uploader/gallery/gallery.css'

const uploader = new FineUploaderTraditional({
    options: {
        cors: {
        //all requests are expected to be cross-domain requests
        expected: true,
      },
        chunking: {
            enabled: false
        },
        deleteFile: {
            enabled: true,
            endpoint: 'https://na.rdheinsberg.de/rest/delfile'
        },
        request: {
            endpoint: 'https://na.rdheinsberg.de/rest/upload'
        },
        retry: {
            enableAuto: true
        }
    }
})

const isFileGone = status => {
    return [
        'canceled',
        'deleted',
    ].indexOf(status) >= 0
}

class FileUpload extends Component {
  constructor() {
    super()
    this.state= {
      submittedFiles : [],
    }

}

    componentDidMount() {
        this.props.uploadedfiles([])
        uploader.on('statusChange', (id, oldStatus, newStatus) => {
            if (newStatus === 'submitted') {
                const submittedFiles = this.state.submittedFiles
                submittedFiles.push(id)
                this.setState({ submittedFiles })
                this.props.uploadedfiles(uploader.methods.getUploads())
            }
            else if (isFileGone(newStatus)) {
                const submittedFiles = this.state.submittedFiles
                const indexToRemove = submittedFiles.indexOf(id)
                submittedFiles.splice(indexToRemove, 1)
                this.setState({ submittedFiles })
                this.props.uploadedfiles(uploader.methods.getUploads())
            }
        })
    }





  render () {
    const delbuttonstyle = {
      background: 'white',
      borderWidth: '0px',
      verticalAlign :'middle',
    }

    const progresssyle =  {
      height: '3px',
      background :'blue'
    }

    return (

      <div>
      <ProgressBar style={progresssyle} uploader={uploader}/>
      <Dropzone uploader={uploader}>
      <FileInput multiple uploader={ uploader }>
          <AttachFileIcon/>
      </FileInput>
        { this.state.submittedFiles.map(id =>
          <div key={ id }>
             <Filename id={ id } uploader={ uploader } /> (<Filesize id={ id } uploader={ uploader } />)
             <DeleteButton style={delbuttonstyle} id={ id } uploader={ uploader }><ClearIcon/> </DeleteButton>
          </div>
        )}
        </Dropzone>
        </div>

      )
  }


}


export default FileUpload
