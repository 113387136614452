import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import SmsIcon from '@material-ui/icons/Sms'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import EmailIcon from '@material-ui/icons/Email'
import Typography from '@material-ui/core/Typography'
import RichTextEditor from 'react-rte'
import MessageReciever from './messagereciever'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField'
import Badge from '@material-ui/core/Badge'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FileUpload from './fileupload'
import './messagecenter.css'

const swal = withReactContent(Swal)
const wota = ['Mo','Di','Mi','Do','Fr','Sa','So']
const monate = ['Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember']


const message = {
  marginLeft:  'auto',
  marginRight : 'auto',
  width : '90%',
  textAlign : 'center',
  padding : '5px',
  marginTop : '5px',
  marginBottom : '5px'
}

const message_settings = {
  marginLeft:  'auto',
  marginRight : 'auto',
  textAlign : 'left',
  padding : '5px',
  marginTop : '5px',
  marginBottom : '5px'
}

const main = {
  marginLeft:  'auto',
  marginRight : 'auto',
  maxWidth : '700px',
}

const modbadge = ({
  badge :
{  width: '60px',
  height : '30px'}
})

const buttonstyle = {
  margin : '5px',
}

const smscount = {
  position : 'relative',
  top : '0',
  right : '0',
  width : '70px',
  marginLeft : 'auto'
}



const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'}
  ],
  BLOCK_TYPE_DROPDOWN: [
    {label: 'Normal', style: 'unstyled'},
    {label: 'Heading Large', style: 'header-one'},
    {label: 'Heading Medium', style: 'header-two'},
    {label: 'Heading Small', style: 'header-three'}
  ],
  BLOCK_TYPE_BUTTONS: [
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'}
  ]
};


class MessageCenter extends Component {
  constructor(props){
    super(props)
    this.state = {
      type : 'email',
      recieverlist : [],
      subject : "",
      value : RichTextEditor.createEmptyValue(),
      smssender : 'NA KrsHS',
      files : []
    }
  }

  componentDidMount () {
    let socket = this.props.socket
    socket.emit('userlist',{'get': 'it'});
    socket.on('userlist response', data => {
    this.setState({userfulllist : data})
    let rec = []
    data.map((u,i) => {  rec.push({value: u.username, label: u.name.last+', '+u.name.first})  });
    this.setState({userlist : rec});
  })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.socket !== this.props.socket) {
      let socket = this.props.socket
      socket.emit('userlist',{'get': 'it'});
    }
  }

  onChange = (value) => {
    this.setState({value});
    if (this.props.onChange) {
      this.props.onChange(
        value.toString('html')
      );
    }
  };

  handleTabChange = (event, value) => {
    this.setState({type : value})
  }

  handleSelectChange = (newValue) => {
    this.setState({recieverlist : newValue})
  };

  handlePreselect = (k) => {
    let rec = []
    let l = this.state.userfulllist.filter(i =>
    {return ('wp' in i && i.wp.indexOf(k) != -1 || k == 'all' )}).map((u,i) => {
      rec.push({value: u.username, label: u.name.last+', '+u.name.first})
    });
    this.setState({recieverlist : rec})
  }

  handleSend = (t) => {
    let socket = this.props.socket
    let messagedata = {
      recieverlist : this.state.recieverlist.map(r => r.value),
      type: this.state.type,
      subject : this.state.subject,
      text : this.state.value.toString('html'),
      textplain : this.state.value.toString('markdown'),
      attachedFiles : (this.state.type == 'email' && this.state.files.length > 0) ? this.state.files.map(i => ({name : i.name, uuid : i.uuid})) : [],
      smssender : this.state.smssender
    }
    let text = (this.state.type == 'sms' ? 'SMS an '+this.state.recieverlist.length+' Empfänger senden?' : 'Email an '+this.state.recieverlist.length+' Empfänger senden?')
    swal.fire({
      text : text,
      confirmButtonText: 'Ja',
      showCancelButton: true,
    }).then((data) => {
      if ('value' in data && data.value == true){
        socket.emit('send message',messagedata)
        swal.fire({text : 'Nachricht gesendet', confirmButtonText : 'Gut!'}).then((data) => {
          window.location.reload()
        })
      }
    });
    // swal.fire({
    //   text : 'Senden noch deaktivert',
    //   confirmButtonText: 'Okay :-(',
    //
    // })
  }

  smslength = () => {
  let len = this.state.value.toString('markdown').length
  let fullsms = Math.floor(len/160)+1
  let zeichen = 160- (len % 160)
  return zeichen+' | '+fullsms
  }

  addFile = (f) => {
    let newf = this.state.files
    newf.push(f)
    this.setState({files : newf})
  }

  uploadedfiles = (f) => {
    this.setState({files : f})
  }



  render () {

  return (
      <div>
        <Paper style={main}>
            <Button variant="outlined"  style={buttonstyle} size="small" color="primary" primary='true' onClick={(event) => this.handlePreselect('all')}>
                Alle Ärzte
            </Button>
            <Button variant="outlined"  style={buttonstyle} size="small" color="primary" primary='true' onClick={(event) => this.handlePreselect('NA_WGB')}>
                  NA Wegberg
            </Button>
            <Button variant="outlined" style={buttonstyle} size="small" color="primary" primary='true' onClick={(event) => this.handlePreselect('LNA_HS')}>
                  LNA HS
            </Button>
            {this.state.userlist &&
                <MessageReciever style={{zIndex : 1000}} userlist={this.state.userlist} value={this.state.recieverlist} onRecChange={this.handleSelectChange} />
            }

                <Tabs  centered value={this.state.type} onChange={this.handleTabChange}>
                  <Tab label="SMS" icon={<SmsIcon />} value='sms' />
                  <Tab label="Email" icon={<EmailIcon />} value='email'/>
                </Tabs>

            {this.state.type == 'email' &&
            <div style={message}>
            <Paper>
            <TextField fullWidth  style = {message}  margin="normal" label="Betreff"
                value={this.state.subject}
                onChange={(e) => this.setState({subject : e.target.value})}
                />
              </Paper>
                <Paper>
                  <RichTextEditor value={this.state.value} onChange={this.onChange} />
                </Paper>
                <Paper style={message_settings}>
                      <FileUpload onFileChoosen={this.addFile} uploadedfiles={this.uploadedfiles} onFileRemoved={this.delFile}/>
                </Paper>
              </div>
            }
            {this.state.type =='sms' &&
            <div style={message}>
              <Paper>
              <Paper style={smscount}>{this.smslength()}</Paper>
                  <TextField fullWidth multiline style = {message} minRows="4" margin="normal" label="SMS-Text"
                  value={this.state.value.toString('markdown')}
                  onChange={(e) => this.setState({value : RichTextEditor.createValueFromString(e.target.value, 'markdown')})}
                  />
              </Paper>
              <Paper style={message_settings}>
                  <FormControl>
                          <Select value={this.state.smssender}  onChange={(e) => this.setState({smssender : e.target.value})}  name="smssender" >
                            <MenuItem value="NA KrsHS">NA KrsHS</MenuItem>
                            <MenuItem value="NA WGB">NA WGB</MenuItem>
                            <MenuItem value="LNA HS">LNA HS</MenuItem>
                            <MenuItem value={this.props.userdata.tel}>{this.props.userdata.tel}</MenuItem>
                          </Select>
                      <FormHelperText>SMS Absender</FormHelperText>
                  </FormControl>
              </Paper>
            </div>
            }
            <Button variant="contained" color="primary" disabled={this.state.recieverlist.length == 0} primary='true' onClick={(e) => this.handleSend('sms')}>
            Absenden
            </Button>

        </Paper>
      </div>


      )


}


}


export default withRouter(MessageCenter)
