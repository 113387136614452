import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CancelIcon from '@material-ui/icons/Cancel';
import LockIcon from '@material-ui/icons/Lock';
import ax_ins from '../../ajax'
import { Document, Page } from  'react-pdf/dist/entry.webpack'
import MaterialTable from 'material-table'
import html2canvas from 'html2canvas'
import JSpdf from 'jspdf'
import green from '@material-ui/core/colors/green'
import grey from '@material-ui/core/colors/grey'

const styles = {
  root: {
    color: grey[300],
    '&$checked': {
      color: green[500],
    },
  },
  checked: {},
};

class ShiftList extends React.Component {
  	constructor(props){
  	  super(props);
      this.state = {
        data : [],
        aktfullname : false
      }
  }

  componentDidMount () {
    let s = this.props.socket
    s.on('abrechnung part', data => {
        this.setState({data : data})
    })


  }

  componentDidUpdate(prevProps) {
    if (this.state.aktfullname !== this.props.data.fullname) {
      let s = this.props.socket
      this.setState({aktfullname : this.props.data.fullname})
      let filter = {fullname : this.props.data.fullname,part : true}
      console.log({...filter},{filter:filter} )
      s.emit('abrechnung',{filter:filter})
    }
  }

  printPDF= () => {
    console.log('print')
    const input = document.getElementById('abrechnung_print');
    html2canvas(input,{scale:3})
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        console.log(canvas)
        const pdf = new JSpdf("l","pt","a4", true);
        const ratio = canvas.width / canvas.height
        console.log(ratio)
        pdf.addImage(imgData, 'png', 20 , 50, 1600/2 , ((1600/canvas.width) * canvas.height)/2,'','MEDIUM'); ;
        // pdf.output('dataurlnewwindow');
        let filterstring = this.state.filter && Object.values(this.state.filter).map((value,i) => {return (typeof value !== 'object' && value.replace(', ','.')) || 'offen'}).join('_')
        pdf.save('abrechnung_'+filterstring+'.pdf');
      })
    ;
  }

  handleClose = () => {
    this.props.closeme(true);
  }

  checkInvoice = (e,row) => {
    console.log(e,row)
    alert('noch ohne Funktion...')
  }

  render () {
    const { classes } = this.props;


    const options ={
    columnsButton: true,
    pageSize : 10,
    exportButton : true,
    //selection: true,
    }

    const columns =  [
      {title : 'Tag', field : 'tag',
        render : rowData => {return (<span onClick={event => this.handleFilterClick(event, 'tag',rowData.tag)}>{''+rowData.tag}</span>) } },
      {title : 'Monat', field : 'monat',
            render : rowData => {return (<span onClick={event => this.handleFilterClick(event, 'monat',rowData.monat)}>{''+rowData.monat}</span>) } },
      {title : 'Standort', field : 'wp',
            render : rowData => {return (<span onClick={event => this.handleFilterClick(event, 'wp',rowData.wp)}>{rowData.wp}</span>) }},
      {title : 'auf Rechnung', field : 'invoice',
        render : rowData => {
          return (
          <div>
          <Checkbox
              checked={rowData.invoice_id == this.props.invoiceid ? true : false}
              value={(new Date()).toLocaleDateString('de-DE')}
              onChange={e => this.checkInvoice(e,rowData)}
              indeterminate
              classes={{
                root: classes.root,
                checked: classes.checked,
              }}
              />
              </div>
          )
      }}
    ]


    return (
      <Dialog  open={this.props.open}
      onClose={this.handleClose}>
          <div id="abrechnung_print">
            <MaterialTable printPDF={this.printPDF} options={options} columns={columns} data={this.state.data} title="Dienste des NA" />
          </div>
        <Button variant="contained" color="secondary" onClick={this.handleClose}>
          Schließen
        </Button>
      </Dialog>
    )

  }
}

export default withStyles(styles)(ShiftList)
