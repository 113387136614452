import React, { Component } from 'react';
import SyncIcon from '@material-ui/icons/Sync'
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled'

const syncoffstyle = {
  fontSize : '30',
  position : 'absolute',
  top : '10',
  right : '10',
  marginTop : '5px',
  color : 'red'
}

const synconstyle = {
  fontSize : '30',
  position : 'absolute',
  top : '10',
  right : '10',
  marginTop: '5px',
  color : 'green'
}

export default class SocketIndicator extends Component {
  constructor(props) {
    super(props)
  }



  render() {
      return (
        this.props.online ? <SyncIcon style={synconstyle}/> : <SyncDisabledIcon style={syncoffstyle}/>
      )
  }
}
