import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const wota = ['Mo','Di','Mi','Do','Fr','Sa','So']
const monate = ['Jan','Feb','März','April','Mai','Juni','Juli','Aug','Sept','Okt','Nov','Dez']

const onewp = {
  width: '350px',
  marginLeft :'auto',
  marginRight : 'auto',
  marginTop : '10px',
  display : 'flex'
}

const head = {
  fontSize : '120%',
  position : 'relative',
  margin : '10px',
  height : '20px'
}

class DPStats extends Component {
  constructor(props) {
    super(props)

    this.state = {
      gotdata : false

    }

  }

  firstdayinmonth = () => {    return new Date(parseInt(this.props.jahr,10),parseInt(this.props.monat,10)-1,0).getDay()  }
  daysinmonth = () => {    return new Date(parseInt(this.props.jahr,10),parseInt(this.props.monat,10),0).getDate()  }
  weekday = (day) => {    return new Date(parseInt(this.props.jahr,10),parseInt(this.props.monat,10)-1, day-1).getDay()  }
  dbmonat= () => {    return this.props.monat+'.'+this.props.jahr  }
  weeks = () => { return Math.ceil((this.firstdayinmonth()+this.daysinmonth())/7)}


  componentDidMount () {
    if (this.props.socket) {
    let socket = this.props.socket
    socket.emit('userstat',{user : this.props.selected_user, monat : this.dbmonat()})
    socket.once('userstat result', data => {
      this.setState({data : data})
      console.log(data)
      this.setState({gotdata : true})
    });
  }
  }


  componentDidUpdate (prevProps) {
    if (prevProps !== this.prevProps) {
      if (prevProps.selected_user !== this.props.selected_user || prevProps.monat !== this.props.monat || prevProps.jahr !== this.props.jahr) {
        let socket = this.props.socket
        socket.emit('userstat',{user : this.props.selected_user, monat : this.dbmonat()})
        socket.once('userstat result', data => {
          this.setState({data : data})
          console.log(data)
          this.setState({gotdata : true})
        });
      }
    }
  }

  render () {
    const data = this.state.data
    return (
      <div>
      Ist nur ein Beispiel für Uschi...
      { data && Object.keys(data).map(wp =>
      <Paper style = {onewp}>
      <div style={head}>{wp}</div>
      <Table>
        <TableBody>
        <TableRow>
          <TableCell>Tagdienste</TableCell>
          <TableCell>{data[wp].filter(i => i.shift == 'TD').length}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Nachtdienste</TableCell>
          <TableCell>{data[wp].filter(i => i.shift == 'ND').length}</TableCell>
        </TableRow>
        </TableBody>
      </Table>
      </Paper>
    )}
      </div>
    )
  }


}


export default DPStats
