import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import DateRangeIcon from '@material-ui/icons/DateRange'
import FilterListIcon from '@material-ui/icons/FilterList';
import PrintIcon from '@material-ui/icons/Print'
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import Switch from '@material-ui/core/Switch';
import MaterialTable from 'material-table'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InvoiceDetail from './invoicedetail'
import { createMuiTheme } from '@material-ui/core/styles';
import html2canvas from 'html2canvas';
import green from '@material-ui/core/colors/green'
import grey from '@material-ui/core/colors/grey'
import JSpdf from 'jspdf';
let counter = 0;


const printbutton = {
  margin: '20px'
}

const styles = {
  root: {
    color: grey[300],
    '&$checked': {
      color: green[500],
    },
  },
  checked: {},
};

const standstyle = {
  fontSize : '60%',
  marginLeft : '10px'
}

function stand(full) {
  let x = new Date()
  if (full) {return x.getDate()+'.'+(x.getMonth()+1)+'.'+x.getFullYear()+' '+x.getHours()+':'+x.getMinutes()+' Uhr'}
  else {return x.getDate()+'.'+(x.getMonth()+1)+'.'+x.getFullYear()}
}




class Abrechnung extends React.Component {
  constructor(props) {
    super(props)

  this.state = {
    filter : {},
    multifilter : true,
    invoicedetail : false,
    invoiceid : false,
    invoicedata : false,
  }
}



  componentDidMount () {
      let s = this.props.socket
      s.on('abrechnung full', data => {
        console.log('got new data')
        this.setState({data : [] })
        this.setState({data : data})
        this.setState({selected : []})
      })
      s.emit('abrechnung',{filter: this.state.filter})
  }

  componentDidUpdate (prevProps) {
  }

  printPDF= () => {
    console.log('print')
    const input = document.getElementById('abrechnung_print');
    html2canvas(input,{scale:3})
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        console.log(canvas)
        const pdf = new JSpdf("l","pt","a4", true);
        const ratio = canvas.width / canvas.height
        console.log(ratio)
        pdf.addImage(imgData, 'png', 20 , 50, 1600/2 , ((1600/canvas.width) * canvas.height)/2,'','MEDIUM'); ;
        // pdf.output('dataurlnewwindow');
        let filterstring = this.state.filter && Object.values(this.state.filter).map((value,i) => {return (typeof value !== 'object' && value.replace(', ','.')) || 'offen'}).join('_')
        pdf.save('abrechnung_'+filterstring+'.pdf');
      })
    ;
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };




  handleFilterClick = (event,field,value) => {
    let tmp = {}
    let s = this.props.socket
    if (this.state.multifilter) {
      tmp = {...this.state.filter}
    } else {
      tmp = {}
    }
    tmp[field] = value
    this.setState({filter : tmp})
    s.emit('abrechnung',{filter : tmp})
  }

  handleFilterDelete = (key) => {
    let s = this.props.socket
    let tmp = this.state.filter
    delete tmp[key]
    this.setState({filter : tmp})
    s.emit('abrechnung',{filter : tmp})
  }





  checkInvoice = (e,n) => {
    console.log(e,n)
    let s = this.props.socket
    let filter = {wp : n.wp,fullname : n.fullname,tag : n.tag, monat : n.monat}
    let set = {invoice : e.target.checked && e.target.value}
    if (!e.target.checked) (set['invoice_id'] = false)
    s.emit('abrechnung update',{filter : filter, set : set, searchfilter: this.state.filter})
    console.log(filter,set)
  }

  checkInvoiceClear = (e,n) => {
    let s = this.props.socket
    let filter = {wp : n.wp,fullname : n.fullname,tag : n.tag, monat : n.monat}
    let invoice = n.invoice
    let set = {invoice_clear : e.target.checked && e.target.value}
    if (e.target.checked && !n.invoice) {
      set['invoice'] = e.target.value
    }
    s.emit('abrechnung update',{filter : filter, set : set, searchfilter: this.state.filter})
    console.log(e,n)
  }

 openInvoices = () => {
   let s = this.props.socket
   let tmp = {...this.state.filter}
   //tmp['$and'] = [{'invoice':{'$nin':[null,false]}},{'invoice_clear':{'$in':[null,false]}}]
   tmp['invoice_clear'] = {'$in':[null,false]}
   this.setState({filter : tmp})
   s.emit('abrechnung',{filter : tmp})
 }

 openInvoiceDetail = (data) => {
   this.setState({invoiceid : data.invoice_id, invoicedata : data})
   this.setState({invoicedetail : true})
 }


  bd = (o) => {
    if (o && 'monat' in o && 'jahr' in o) {
      return ''+o.monat+'.'+o.jahr
    } else {
      return '-'
    }
  }

  tablePageChange = (e) => {
    console.log(e)
  }



  render() {
    const { classes } = this.props;
    const columns =  [
      {title : 'Tag', field : 'tag',
        render : rowData => {return (<span onClick={event => this.handleFilterClick(event, 'tag',rowData.tag)}>{''+rowData.tag}</span>) } },
      {title : 'Monat', field : 'monat',
            render : rowData => {return (<span onClick={event => this.handleFilterClick(event, 'monat',rowData.monat)}>{''+rowData.monat}</span>) } },
      {title : 'Standort', field : 'wp',
            render : rowData => {return (<span onClick={event => this.handleFilterClick(event, 'wp',rowData.wp)}>{rowData.wp}</span>) }},
      {title : 'Name', field : 'fullname',
            render : rowData => {return (<span onClick={event => this.handleFilterClick(event, 'fullname',rowData.fullname)}>{rowData.fullname}</span>) } },
      {title : 'Dienst', field : 'shift', render : rowData => rowData.shift.substr(1) },
      {title : 'Rechnung', field : 'invoice',
        render : rowData => {
          return (
          <div>
          <Checkbox
              checked={rowData.invoice ? true : false}
              value={(new Date()).toLocaleDateString('de-DE')}
              onChange={e => this.checkInvoice(e,rowData)}
              indeterminate
              classes={{
                  root: classes.root,
                  checked: classes.checked,
  	         }}
            />

            <span onClick={event => this.handleFilterClick(event, 'invoice',rowData.invoice)}>{rowData.invoice}</span>
            {rowData.invoice_id && <br/>}
            {rowData.invoice_id && <span onClick={event => this.handleFilterClick(event, 'invoice_id',rowData.invoice_id)}>gleiche Rechnung</span>}
            {rowData.invoice_id && <SearchIcon onClick={event => this.openInvoiceDetail(rowData)}/>}

          </div>)
        }
     },
      {title :'bezahlt?', field : 'invoice_clear',
        render : rowData => {
          return (
            <div>
            <Checkbox
                checked={rowData.invoice_clear ? true : false}
                value={(new Date()).toLocaleDateString('de-DE')}
                onChange={e => this.checkInvoiceClear(e,rowData)}
                indeterminate
                classes={{
                root: classes.root,
                checked: classes.checked,
              }}
              />
              <span onClick={event => this.handleFilterClick(event, 'invoice_clear',rowData.invoice_clear)}>{rowData.invoice_clear}</span>
            </div>
          )
        }

    }
    ]

    const options ={
    columnsButton: true,
    pageSize : 10,
    exportButton : true,
    //selection: true,
    }

    const actions = []
    //   {
    //       icon: 'archive',
    //       tooltip: 'Rechnung hinzufügen',
    //       onClick: (event, rows) => {
    //         console.log('rows',rows)
    //       },
    //   },
    //
    // ]

    return (
      <Paper>
      {Object.keys(this.state.filter).map((k,i) =>  (
        <Chip
        label={(typeof this.state.filter[k] == 'object' ? ( k == 'invoice_clear' ? 'offene Rechnungen' : Object.keys(this.state.filter[k]).map(i => this.state.filter[k][i]).sort().join('.')) : this.state.filter[k])}
        onDelete={e => this.handleFilterDelete(k)}
        color="primary"
        key={i+'chip'}
        />)
      )}
      { !('$and' in this.state.filter) && <Button variant="outlined" onClick={this.openInvoices}>
        offene Rechnungen
      </Button>}
      <div id="abrechnung_print">
        <MaterialTable printPDF={this.printPDF} onChangePage={this.tablePageChange} options={options}
        columns={columns} actions={actions} data={this.state.data} title="Abrechnung" currentPage={2}/>
      </div>
      <InvoiceDetail open={this.state.invoicedetail} closeme={e => this.setState({invoicedetail : false})} userdata={this.props.userdata} socket={this.props.socket} invoiceid={this.state.invoiceid} data={this.state.invoicedata}/>
      </Paper>
    );
  }
}



export default withStyles(styles)(Abrechnung);
