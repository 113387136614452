import React, { Component, Fragment } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import {AuthProvider, AuthConsumer } from "react-check-auth";
import Calendarcontainer from './components/user/calendarcontainer'
import Dpbau from './components/manager/dp_bau'
import DPrint from './components/manager/dpprint'
import Contactlist from './components/user/contactlist'
import Rechnungen from './components/user/rechnungen'
import Loginpopup from './components/login'
import PWResetPopup from './components/pwreset'
import ErrorPopup from './components/errorpopup'
import ax_ins from './ajax'
import Appbar from './menu/appbar'
import ErrorIcon from '@material-ui/icons/Error'
import openSocket from 'socket.io-client';
import { DragDropContext } from 'react-dnd';
import HTML5Backend, { NativeTypes } from 'react-dnd-html5-backend';
import SocketIndicator from './socketindicator'
import WPManage from './components/manager/wp_manage'
import MessageCenter from './components/manager/message/messagecenter'
import Preferences from './components/preferences'
import Snackbar from '@material-ui/core/Snackbar'
import DPMcontainer from './components/manager/dpmanagecontainer'
import Abrechnung from './components/manager/abrechnung'
import AddUser from './components/manager/adduser'
import QRCam from './components/qrreader';
import CertInfo from './components/qrreader';
const socurl = window.location.host === 'localhost:3000' ? "https://na.rdheinsberg.de/" : window.location.host;
const VERSION = 2.5
//const socket = openSocket(socurl);
//import {Menu, Pages} from './page_components'

const syncstyle = {
  fontSize : '30',
  position : 'absolute',
  top : '10', 
  right : '10',
  marginTop : '5px'
}

const errorstyle = {
  fontSize : '30',
  position : 'absolute',
  top : '10',
  right : '50',
  zIndex : '100',
  marginLeft : '30vw',
  marginTop : '5px'
}

if (!String.prototype.startsWith) {
    String.prototype.startsWith = function(searchString, position){
      position = position || 0;
      return this.substr(position, searchString.length) === searchString;
  };
}

class App extends Component {
  //holt vom server: access_token, userdata
  //startet openSocket
  //an Calendarcontainer: userdata, socket
  constructor(props) {
    super(props)

  this.state = {
    ismanager: false,
    open_login : false,
    udatachanged : false,
    userdata : {},
    access_token : localStorage.getItem('access_token') || sessionStorage.getItem('access_token'),
    socket: false,
    online : false,
    open_pwreset : false,
    open_errorpopup : false,
    snackbarmessage : ""
  }
}



  gotuser = (userdata,stayloggedin) => {
    this.setState({open_login : false})
    this.setState({access_token : userdata['access_token']});
    console.log(stayloggedin)
    if (stayloggedin == true) {
      localStorage.setItem('access_token', userdata['access_token']);}
    else {
      sessionStorage.setItem('access_token', userdata['access_token']) }
    console.log('saved token');
    ax_ins.get('checktoken?jwt='+this.state.access_token).then((response) => {
        console.log(response);
        this.setState({userdata : response.data});
          if ('manage_wp' in response.data) this.setState({ismanager : true})
        this.open_soc();
    },).catch((error) => {
      console.log(error);
      this.setState({open_login : true});
    })
  }

  open_soc = () => {
     this.setState({socket: openSocket(socurl, {query: {jwt: this.state.access_token}})});
     this.state.socket.emit('connected');
     this.state.socket.on('info', data => {
       console.log(data)
       this.setState({snackbarmessage : data.text})
       this.setState({snackbar_open : true})

     });
     this.state.socket.on('connect', () => {
       this.setState({online : true})
       this.state.socket.emit('check version',{version:VERSION},data => {
         if (data.reboot) {
          caches.delete("workbox-precache-https://na.rdheinsberg.de/")
          caches.delete("workbox-precache-https://na.rdheinsberg.de/-temp")
           window.location.reload()
         }
       })
      });
     this.state.socket.on('new userdata', data => {
       this.setState({userdata : data})
       this.setState({snackbarmessage : 'Benutzerdaten geändert'})
       this.setState({snackbar_open : true})      
      })
    if ('manage_wp' in this.state.userdata) this.setState({ismanager : true})
     this.state.socket.on('disconnect', () => this.setState({online:false}));
  }

  PWReset = () =>{
  this.setState({open_login : false})
  this.setState({open_pwreset : true})
  }

  PWReseted = (ok) => {
    this.setState({open_pwreset : false})
    if (ok)
      this.setState({snackbarmessage : 'SMS gesendet'})
    else
      this.setState({snackbarmessage :'Nummer unbekannt'})
    this.setState({snackbar_open : true})
    this.setState({open_login : true})

  }

  ErrorSend = (ok) =>{
    this.setState({open_errorpopup : false})
    if (ok)
      this.setState({snackbarmessage : 'SMS gesendet'})
    else
      this.setState({snackbarmessage :'irgendwas schief gelaufen'})
    this.setState({snackbar_open : true})
  }

  componentDidMount () {
    ax_ins.get('checktoken?jwt='+this.state.access_token).then((response) => {
        console.log(response);
        this.setState({userdata : response.data});
        this.open_soc();
    },).catch((error) => {
      console.log(error);
      this.setState({open_login : true});
    });
  }




  render() {
    return (
      <Router>
    <div>
        <SocketIndicator online={this.state.online}/>
        <ErrorIcon onClick={(e) => this.setState({open_errorpopup : true}) } color="secondary" style={errorstyle}/>
        <ErrorPopup daten={this.state} open={this.state.open_errorpopup} onErrorSMSClose={this.ErrorSend} socket={this.state.socket}/>
        <Appbar userdata={this.state.userdata} ismanager={this.state.ismanager} version={VERSION}/>
        <Loginpopup open = {this.state.open_login} onGotuser={this.gotuser} resetpw={this.PWReset}/>
        <PWResetPopup open = {this.state.open_pwreset} socket={this.state.socket} pwreseted={this.PWReseted} />
        <hr />
        {this.state.online &&
        <Switch>

                      <Route exact path="/" render={(props) => <Calendarcontainer userdata={this.state.userdata} ismanager={this.state.ismanager} socket={this.state.socket}/>}/>
                      <Route exact path="/:type/:monat" render={(props) => <Calendarcontainer userdata={this.state.userdata} ismanager={this.state.ismanager} socket={this.state.socket}/>}/>
                      <Route path="/Kontaktliste/" render={(props) => <Contactlist userdata={this.state.userdata} ismanager={this.state.ismanager} socket={this.state.socket} />}/>
                      <Route path="/Rechnungen/" render={(props) => <Rechnungen userdata={this.state.userdata} ismanager={this.state.ismanager} socket={this.state.socket} />}/>
                      <Route path="/Einstellungen/" render={(props) => <Preferences userdata={this.state.userdata}  socket={this.state.socket} />}/>
                      <Route path="/Covid-Zertifikat/" render={(props) => <CertInfo userdata={this.state.userdata}  socket={this.state.socket} />}/>

                      {this.state.ismanager &&  <Route path="/DP-Bau/:buildview/:wp/:monat" render={(props) => <Dpbau userdata={this.state.userdata} view="calendar" socket={this.state.socket}/>}/>}
                      {this.state.ismanager &&  <Route path="/WPManage/" render={(props) => <DPMcontainer userdata={this.state.userdata} view="calendar" socket={this.state.socket} />}/>}
                      {this.state.ismanager &&  <Route path="/messagecenter/" render={(props) => <MessageCenter userdata={this.state.userdata} type="email" socket={this.state.socket} />}/>}
                      {this.state.ismanager &&  <Route path="/Abrechnung/" render={(props) => <Abrechnung userdata={this.state.userdata} socket={this.state.socket} />}/>}
                      {this.state.ismanager &&  <Route path="/neuerNutzer/" render={(props) => <AddUser userdata={this.state.userdata} socket={this.state.socket} />}/>}


        </Switch>
      }
        <Snackbar
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
          }}
          open={this.state.snackbar_open}
          onClose={() => this.setState({snackbar_open : false})}
          autoHideDuration={4000}
          message={this.state.snackbarmessage}
        />
    </div>
    </Router>



    )

}
}


export default DragDropContext(HTML5Backend)(App);
