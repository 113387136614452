import React, { Component } from 'react';
import '../days/days.css';
import Shift from './shifts'
import { DropTarget  } from 'react-dnd'



function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType()
  };
}

const dropzone = {
  drop(props, monitor, component) {
    let data = {'is': monitor.getItem().props.shift,'tag':props.tag,'type':props.type,'was':props.current_booked}
    component.props.onDnD(data)
    return {props};
  }
}

class DTBookedShiftPlace extends Component {
  constructor(props) {
    super(props)
  }

  handleDblClck = (shift) => {
    let data = {is: shift, tag:this.props.tag,type:this.props.type, was: 'unknown'}
    console.log('dblclck')
    this.props.onDnD(data)
    //component.props.onDnD(data)

  }


  render() {
    const { isOver, canDrop, connectDropTarget } = this.props;
    return connectDropTarget(
      <div className={(this.props.type === 'TD' ? 'dtday ' : 'dtnight ')+this.props.listview}
        style={this.props.listview && {height: 'calc( 15px *'+this.props.rowelemcount+')' }}>
        {this.props.current_booked.map((o,i) => <Shift key={i} highlightuser={this.props.highlightuser} cert={this.props.userlist[o.user]} listview={this.props.listview} tag={this.props.tag} type={this.props.type}
        onDblClck={this.handleDblClck} shift={o}/>) }
        &nbsp;
      </div>
    )
  }

}

export default DropTarget((props) => {return ['extern-',props.type+'-'+props.tag];},dropzone,collect)(DTBookedShiftPlace);
