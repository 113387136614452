import React, { Component } from 'react'
import FineUploaderTraditional from 'fine-uploader-wrappers'
import QrReader from 'react-qr-reader'
import FileUpload from './manager/message/fileupload'
import FileInput from 'react-fine-uploader/file-input'
import Filename from 'react-fine-uploader/filename'
import Filesize from 'react-fine-uploader/filesize'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import Gallery from 'react-fine-uploader'
import ClearIcon from '@material-ui/icons/Clear';
import DeleteButton from 'react-fine-uploader/delete-button'
import Thumbnail from 'react-fine-uploader/thumbnail'
import Dropzone from 'react-fine-uploader/dropzone'
import {isMobile} from 'react-device-detect';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper'
import { withRouter } from 'react-router'
import { Button, Input } from '@material-ui/core'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import '../App.css'

const swal = withReactContent(Swal)

let wait = false

const progresssyle =  {
    height: '3px',
    background :'blue'
  }

  const center = {
    maxWidth : '600px',
    marginLeft : 'auto',
    marginRight : 'auto'
  }
  
  const right = {
    float : 'right',
    marginLeft : 'auto',
    marginRight : '10px'
  }
  
  const left = {
    float: 'left'
  }

class QRCam extends Component {
  state = {
    result: 'No result',
    wait : false
  }

  componentDidMount () {
  }

  componentWillUnmount () {

  }
 
  handleScan = data => {
    if (data && !wait) {
        wait = true
        let socket = this.props.socket;
        socket.emit('check cert',{'cert':data},result => {
            console.log(result)
            let text = 'Zertifikat '+(result.success ? 'OK' : 'ungültig weil '+result.message.message)
            swal.fire({
                text : text,
                confirmButtonText: 'OK',
                cancelButtonText : 'neu scannen',
                showCancelButton: true,
              }).then((data) => {
                if ('value' in data && data.value == true){
                wait = false
                this.props.close()
              } else {
                wait = false
              }
            });
        })
    }
  }
  
  handleError = err => {
    console.error(err)
  }

  render() {
    return (
        <>
      <div style={{maxHeight:'500px',maxWidth:'500px'}}>
        <QrReader
          delay={1000}
          onError={this.handleError}
          onScan={this.handleScan}
          style={{ width: '100%' }}/>
      </div>
      </>
    )
  }
}


const uploader = new FineUploaderTraditional({
        options: {
            cors: {
            //all requests are expected to be cross-domain requests
            expected: true,
            },
            chunking: {
                enabled: false
            },
            deleteFile: {
                enabled: false,
            },
            request: {customHeaders: {
                "Authorization": "Bearer " + localStorage.getItem('access_token') || sessionStorage.getItem('access_token')
            },
                endpoint: 'https://na.rdheinsberg.de/rest/certupload'
            },
            retry: {
                enableAuto: true
            }
        }
})


class CertUpload extends Component {
        constructor() {
          super()
          this.state= {
            submittedFiles : [],
          }
      
      }
      
          componentDidMount() {
              uploader.on('statusChange', (id, oldStatus, newStatus) => {
                  if (newStatus === 'submitted') {
                    console.log('test')
                  }
              })
          }


    render (){
    return (
            <div style={{height:'100px',width:'100px',margin:'20px'}}>
            <progressBar style={progresssyle} uploader={uploader}/>
            <Dropzone uploader={uploader}>
                    <FileInput uploader={ uploader }>
                        <AttachFileIcon/>
                    </FileInput>
            </Dropzone>
            </div>
      )
    }


}


const blankstate = { 
  shots : '0/0',
  dos : '1970-01-01',
  history : [],
  valid : [false,{message:'nicht vorhanden'}]
}


class CertInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shots : '0/0',
      dos : '1970-01-01',
      history : [],
      valid : [false,{message:'nicht vorhanden'}]
    }
    this.inputRef= React.createRef();
  } 
  

  componentDidMount () {
    this.props.socket.emit('cert',{},data => this.setState(data))
  }

  gotnewcert = () => {
    this.props.socket.emit('cert',{},data => this.setState(data))
    this.setState({scanqr : false})
    this.setState({uploadqr:false})
  }

  FileSelected = (e) => {
    console.log(e)
  }

  render () {
    return (
      <Paper style={center}>
          <input type='file'  name='qqfile'  ref={this.inputRef}  onChange={e => this.FileSelected(e)}  style={{ display: 'none' }}/>  
         Aktuelles Zertifikat ist <b>{this.state.valid[0] ? 'gültig' : 'ungültig'}</b> weil {this.state.valid[1].message}<br/>
         {/* <div style={{width:'100%'}}>
          <FileInput uploader={ uploader } className='ficustom' accept='image/*' style={{width:'100%'}}>
                        <Button variant='contained' color="primary" style={{marginTop:'10px'}} fullWidth>Bild mit QR-Code auswählen</Button>
          </FileInput>
          </div> */}


         {!this.state.scanqr && <Button onClick={e => this.setState({scanqr : true})}  variant='contained' color="primary" style={{marginTop:'10px'}} fullWidth>QR-Code scannen</Button>}
          {this.state.scanqr && <>
            <Button onClick={e => this.setState({scanqr : false})}  variant='contained' color="primary" style={{marginTop:'10px'}} fullWidth>Scanvorgang abbrechen</Button>
            <QRCam socket={this.props.socket} close={e => this.gotnewcert()}/>
           </>}

           <br/><br/>
          {!!this.state.history.length && 'Verlauf:'}
          <List>
            {this.state.history.map((h,i) => <ListItem key={'hist_i'+i}>{h}</ListItem>)}
          </List>
      </Paper>
    )
  }



}


export default withRouter(CertInfo)