import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ClearIcon from '@material-ui/icons/Clear';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import ForwardIcon from '@material-ui/icons/Forward';
import TextField from '@material-ui/core/TextField';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import './dpnotify.css'

const swal = withReactContent(Swal)

const wota = ['Mo','Di','Mi','Do','Fr','Sa','So']



class DPnotifyentry extends Component {
constructor(props) {
  super(props)

  this.state={
    open:false
  }
}

weekday = (day) => {    return new Date(this.props.jahr,parseInt(this.props.monat,10)-1, day-1).getDay()  }

handleClick = () => {
  this.setState(state => ({ open: !state.open }));
};

handleChange = (event,t,n,s,i) => {
  this.props.onCheckChange(event,t,n,s,i)
}

handleDelete = (event,t,n,s,i) => {
  let text = 'Fehler, bitte abbrechen'
  if (t == 'group') text = 'Alle Benachrichtigungen für '+n+ ' löschen?'
  if (t == 'shift') text = 'Benachrichtigungen für '+n+' ('+s.shift+' am '+s.tag+'. '+(s.choosen? ' -rein-' : ' -raus-') + ') löschen?'
  swal.fire({
    text : text,
    confirmButtonText: 'Ja',
    showCancelButton: true,
  }).then( (data) => {
      if ('value' in data && data.value == true){
        this.props.deleteNotify(t,n,s,i)
      }
  });
}

all_checked = () => {
  return this.props.shifts.every(s => {return s.checked})
}

inoutclass = () => {
  if (this.props.shifts.every(s => {return s.choosen})) return 'incolor'
  if (this.props.shifts.every(s => {return !s.choosen})) return 'outcolor'
  return 'mixedcolor'
}


render () {



  let fn = this.props.user

  return (
    <div>
   <ListItem key={fn} dense button className={'list_name '+ this.inoutclass()} >
     <Checkbox checked={this.all_checked('checked')} disableRipple onChange={(event) => {this.handleChange(event,'group',fn)}}/>
     <ListItemText primary={fn +' ('+this.props.shifts.length+')'} onClick={this.handleClick} />
     <IconButton aria-label="Comments" onClick={(event) => {this.handleDelete(event,'group',fn)}}>
       <ClearIcon />
     </IconButton>
     {this.state.open ? <ExpandLess onClick={this.handleClick} /> : <ExpandMore onClick={this.handleClick} />}
   </ListItem>
   <Collapse in={this.state.open} timeout="auto" unmountOnExit>
   <ListItem>
   <TextField
   value = {this.props.comment}
   onChange={(event) => {this.handleChange(event,'comment',fn)}}
   label="Anmerkungen"/>
   </ListItem>
   <List dense component="div">
    {this.props.shifts.map((s,i) =>
      <ListItem key={fn+'_'+i} dense button className={'list_shift ' + (s.choosen ? 'incolor' : 'outcolor') } >
        <Checkbox checked={s.checked} disableRipple  onChange={(event) => {this.handleChange(event,'shift',fn,s,i)}}/>
        <ListItemText primary={wota[this.weekday(s.tag)]+', '+s.tag+'.: '+ (s.choosen ? s.shift+' rein' : s.shift+' raus' ) } />
          <ListItemSecondaryAction>
          <IconButton aria-label="Comments" onClick={(event) => {this.handleDelete(event,'shift',fn,s,i)}}>
            <ClearIcon />
          </IconButton>
          </ListItemSecondaryAction>
      </ListItem>
     )}
   </List>
   </Collapse>
   <Divider />
   </div>


  )


}

}

export default DPnotifyentry
