import React from 'react';
import PropTypes from 'prop-types';
import { Route, Link, withRouter } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogContent from '@material-ui/core/DialogContent';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done'
import CancelIcon from '@material-ui/icons/Cancel'
import Switch from '@material-ui/core/Switch';
import '../general.css'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import setUserPW from './setuserpw'


const swal = withReactContent(Swal)


const editstyle = {
  maxWidth : '80%'
}

const edithalfstyle = {
  maxWidth : '40%'
}

const donestyle = {
  color : '#00b600',
  fontSize : '37px'
}

const cancelstyle = {
  color : '#e10808',
  fontSize : '37px'
}

const paperstyle = {
  marginLeft : 'auto',
  marginRight :'auto',
  maxWidth: '90vw'
}

const confirmcancelstyle = {
  display: 'flex',
  float : 'right',
marginRight: '-20px'
}

const bottomright = {
  float: 'right',
  // position: 'absolute',
  // marginLeft : 'auto',
  // marginRight : '10px'
}


class UserPreferences extends React.Component {
  constructor(props){
    super(props)

    this.state = {
        ro : { name :true,
            tel : true,
            email :true
      },
    }

  }

  componentDidMount () {
    if ('username' in this.props.userdata) {
      this.cancelEdit()
  }
}
  componentDidUpdate (prevProps) {
    if (prevProps.userdata !== this.props.userdata && 'username' in this.props.userdata) {
      this.cancelEdit()
    }
  }

  PWchangeClose = (pwchanged) => {
    if (pwchanged)
      this.props.history.push('/')
    else
      this.setState({changepwopen : false})
  }



  handleChange = (e,t) => {
    let tmp = {...this.state.name}
    tmp[t] = e.target.value
    this.setState({tmp})
  }

  toggleReadonly = (t) => {
    let tmp = {...this.state.ro}
    tmp[t] = !tmp[t]
    this.setState({ro : tmp})

  }

  cancelEdit = () => {
    this.setState({tel : this.props.userdata.tel})
    this.setState({email : this.props.userdata.email})
    this.setState({first : this.props.userdata.name.first})
    this.setState({last : this.props.userdata.name.last})
    this.setState({username : this.props.userdata.username})
    this.setState({wp : this.props.userdata.wp})
    this.state.ro.name = true
    this.state.ro.tel = true
    this.state.ro.email = true
  }

  confirmEdit = () => {
    let socket = this.props.socket
    console.log('start edit')
    if (this.props.userdata.name.first !== this.state.first || this.props.userdata.name.last !== this.state.last || this.props.userdata.tel !== this.state.tel || this.props.userdata.email !== this.state.email) {
      if (this.state.first &&  this.state.last  && this.state.tel  && this.state.email ) {
        socket.emit('admin update userdata',{username: this.state.username, first : this.state.first, last: this.state.last, tel:this.state.tel,email:this.state.email})
        this.cancelEdit()
    } else {
      console.log(this.state)
      swal.fire({
        text : 'Bitte keine Felder leer lassen',
        confirmButtonText: 'Okay'
      })
        this.cancelEdit()
    }
    }

  }

  handleGroupChange = (e,wp) => {
    let s = this.props.socket
    let wp_list = this.state.wp

    if (wp_list.indexOf(wp) == -1) {
      wp_list.push(wp)
    } else {
      wp_list = wp_list.filter(i => i !== wp)
    }
    this.setState({wp:wp_list})
    s.emit('change groups',{username: this.state.username,wp:wp_list})
  }


  render () {
  return (
    <div>
    { 'first' in this.state  &&
      <Paper style={paperstyle}>
      <CancelIcon style={bottomright} onClick={this.props.CloseEdit}/>
      <DialogContent >
        <TextField style={editstyle}
           value = {this.props.userdata.username}
           label="Benutzername"
           InputProps={{readOnly: true, }}
         />
         </DialogContent>
         <DialogContent >
         <TextField style={edithalfstyle}
         required
            value = {this.state.first}
            label="Vorname"
            onChange={(e) => this.setState({first:e.target.value})}
            InputProps={{readOnly: this.state.ro.name, }}

          />
          <TextField style={edithalfstyle}
          required
             value = {this.state.last}
             label="Nachname"
             onChange={(e) => this.setState({last:e.target.value})}
             InputProps={{readOnly: this.state.ro.name, }}
           />
           {this.state.ro.name ?  <EditIcon onClick={(e) => this.toggleReadonly('name')} />
             :  <div style={confirmcancelstyle}><CancelIcon style={cancelstyle}  onClick={(e) => this.cancelEdit()}/><DoneIcon style={donestyle} onClick={(e) => this.confirmEdit()}/></div>   }

           </DialogContent>
           <DialogContent>
           <TextField style={editstyle}
            required
              value = {this.state.tel}
              label="Telefonnummer"
              InputProps={{readOnly: this.state.ro.tel, }}
                onChange = {(e) => this.setState({tel:e.target.value})}
            />
            {this.state.ro.tel ?  <EditIcon onClick={(e) => this.toggleReadonly('tel')} />
              :  <div style={confirmcancelstyle}><CancelIcon style={cancelstyle}  onClick={(e) => this.cancelEdit()}/><DoneIcon style={donestyle} onClick={(e) => this.confirmEdit()}/></div>   }

            </DialogContent>
            <DialogContent>

            <TextField style={editstyle}
                required
               value = {this.state.email}
               label="Email"
               InputProps={{readOnly: this.state.ro.email, }}
               onChange = {(e) => this.setState({email:e.target.value})}
             />
             {this.state.ro.email ?  <EditIcon onClick={(e) => this.toggleReadonly('email')} />
               :  <div style={confirmcancelstyle}><CancelIcon style={cancelstyle}  onClick={(e) => this.cancelEdit()}/>
               <DoneIcon style={donestyle}  onClick={(e) => this.confirmEdit()}/></div>   }
             </DialogContent>
             <DialogContent>
             <FormGroup row>
             {this.props.admindata.manage_wp.map( (wp,i) => (
               <FormControlLabel key={'wp_change_'+i}
                   control={
                    <Switch
                        checked={this.state.wp.indexOf(wp) !== -1}
                        onChange={e => this.handleGroupChange(e,wp)}
                        value={wp}
                    />
                  }
                  label={wp}
                  />
             )
             )}
             </FormGroup>
             </DialogContent>
      </Paper>
    }
    </div>
  )}
}

UserPreferences.propTypes = {
  userdata: PropTypes.object.isRequired
};

export default withRouter(UserPreferences)
