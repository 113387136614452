import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import ClearIcon from '@material-ui/icons/Clear';
import ListIcon from '@material-ui/icons/List';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DPnotifyentry from './dpnotifyentry';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import '../calendar.css';
import './notifylist.css';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


const swal = withReactContent(Swal)
const wota = ['Mo','Di','Mi','Do','Fr','Sa','So']
const monate = ['Jan','Feb','März','April','Mai','Juni','Juli','Aug','Sept','Okt','Nov','Dez']

const gsm = "@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞ\x1bÆæßÉ !\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ`¿abcdefghijklmnopqrstuvwxyzäöñüà{}[~]|€^"


const style = {
  marginRight :'auto',
  marginLeft : 'auto'
}

const rstyle = {
  height : '20px'
}

class DPnotify extends Component {
  //aus parent: selected_user, userdata, monat, jahr, socket
  //holt vom server: current_offers von selected_user
  //an Calday: current_offers[tag], tag
    // zurück offer_day
  //an OfferPopup: current_offers[offer_day], allowed_wp,
    // zurück wp -> shift -> true/false

  constructor(props) {
    super(props)

    //let datum = this.props.datum;
    var datum = '01.09.2018';
    this.state = {
        notifylist : {},
        open : true,
        comment : ''

    }
}

  firstdayinmonth = () => {    return new Date(this.props.jahr,parseInt(this.props.monat,10)-1,0).getDay()  }
  daysinmonth = () => {    return new Date(this.props.jahr,parseInt(this.props.monat,10),0).getDate()  }
  weekday = (day) => {    return new Date(this.props.jahr,parseInt(this.props.monat,10)-1, day).getDay()  }
  dbmonat= () => {    return this.props.monat+'.'+this.props.jahr  }



  componentDidMount () {
    if (this.props.socket) {
    let nl = this.props.notify.reduce((obj, item) => {
       item['checked'] = true
       if (item.fullname in obj) {
         obj[item.fullname].shifts.push(item)
       } else {
         obj[item.fullname] = {}
         obj[item.fullname]['comment'] = ""
         obj[item.fullname]['shifts'] = []
         obj[item.fullname].shifts.push(item)
       }
       return obj
     },{})
    this.setState({notifylist : nl})
    let socket = this.props.socket
    socket.emit('edit notify',{'wp':this.props.selected_wp,'monat':this.dbmonat()})
  }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.notify != this.props.notify) {
      let nl = this.props.notify.reduce((obj, item) => {
         item['checked'] = true
         if (item.fullname in obj) {
           obj[item.fullname]['shifts'].push(item)
         } else {
           obj[item.fullname] = {}
           obj[item.fullname]['comment'] = ""
           obj[item.fullname]['shifts'] = []
           obj[item.fullname].shifts.push(item)
         }
         return obj
       },{})
      this.setState({notifylist : nl})
    }
    if (prevProps.selected_wp !== this.props.selected_wp || prevProps.monat !== this.props.monat) {
      let socket = this.props.socket
      socket.emit('edit notify stop',{'wp':prevProps.selected_wp,'monat':prevProps.monat+'.'+prevProps.jahr})
      socket.emit('edit notify',{'wp':this.props.selected_wp,'monat':this.dbmonat()})
    }
  }

  componentWillUnmount () {
    let socket = this.props.socket
    socket.emit('edit notify stop',{'wp':this.props.selected_wp,'monat':this.dbmonat()})
  }

  onCheckChange = (e,t,n,s,i) => {
    console.log(e.target.checked);
    let l = this.state.notifylist
    if (t == 'group') {
      l[n].shifts = l[n].shifts.map(s => {
         s.checked = e.target.checked
         return s
       })
    } else if (t == 'shift') {
      l[n].shifts[i].checked = e.target.checked;
    }
    else {
      if (gsm.indexOf(e.target.value.substr(-1,1)) > -1)
        l[n].comment = e.target.value
    }
    this.setState({notifylist : l })
  }

  onTextChange = (e) => {
      if (gsm.indexOf(e.target.value.substr(-1,1)) > -1)
        this.setState({comment: e.target.value})
  }

  onDeleteNotify = (t,n,s,i) => {
    let socket = this.props.socket
    let query = {'wp' : this.props.selected_wp, 'monat' : this.dbmonat()}
    query['fullname'] = n
    if (s) {
       query['tag'] = s.tag
       query['shift'] = s.shift
       query['choosen'] = s.choosen
       query['user'] = s.user
     }
    socket.emit('del notifies',query)
  }

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleSendNotifications = () => {
    swal.fire({
      text : 'Benachrichtigungen senden?',
      confirmButtonText: 'Ja',
      showCancelButton: true,
    }).then((data) => {
      console.log(data)
      if ('value' in data && data.value == true){
       let socket = this.props.socket
       socket.emit('notify users',{'wp':this.props.selected_wp,'monat':this.dbmonat(),
       'global_comment':this.state.comment, 'notifies': this.state.notifylist})
       socket.once('notifies send', data => {
         if ('send' in data && data.send === true)
         swal.fire({
           text : 'Benachrichtigungen gesendet',
           confirmButtonText: 'Okay',

         })
      });
     }
     });

  }

  render() {

    let nl = this.state.notifylist || {}

    return (
    <div className="Liste" style={style}>
      <Paper>
      <List dense component="nav">
      <ListItem key='main_1' dense button  className='list_name'onClick={this.handleClick} >
      <ListItemText disableTypography primary="Ausstehende Benachrichtigungen" />
      {this.state.open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <ListItem key='main_2' dense button  className='list_name'>
        <TextField  onChange={(event) => {this.onTextChange(event)}}
          value = {this.state.comment}
           label="Anmerkungen"/>
      </ListItem>
      <Divider />
       <Collapse in={this.state.open} timeout="auto" unmountOnExit>
        {Object.keys(nl).map((fn,i) =><DPnotifyentry deleteNotify={this.onDeleteNotify} key={i} user={fn}
        monat={this.props.monat} jahr={this.props.jahr} onCheckChange={this.onCheckChange}
        comment={nl[fn].comment} shifts={nl[fn].shifts}/>)}
      </Collapse>
      </List>
      <Button variant="contained" color="secondary" onClick={this.handleSendNotifications}>
        Absenden
      </Button>
      </Paper>
    </div>
    );
  }
}

DPnotify.propTypes = {
  userdata: PropTypes.object.isRequired
};



export default DPnotify
