import React, { Component } from 'react'
import List from '@material-ui/core/List';
import { Route, Link, withRouter } from "react-router-dom";
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button'
import SmsIcon from '@material-ui/icons/Sms'
import CallIcon from '@material-ui/icons/Call'
import EditIcon from '@material-ui/icons/Edit'
import HealingIcon from '@material-ui/icons/Healing';
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import Input from '@material-ui/core/Input';
import {isMobile} from 'react-device-detect';
import UserPreferences from '../manager/userpreferences'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import '../../App.css';
import { Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';


const swal = withReactContent(Swal)

const searchfield = {
  margin : '5px'
}


const center = {
  maxWidth : '600px',
  marginLeft : 'auto',
  marginRight : 'auto'
}

const right = {
  float : 'right',
  marginLeft : 'auto',
  marginRight : '10px'
}

const left = {
  float: 'left'
}

const name = {
  fontWeight : 'bold'
}

class Contactlist extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search : '',
      editusername :false,
      modcovcert : false,
    }
  }

  componentDidMount () {
    if (this.props.socket) {
      let s = this.props.socket
      s.on('contactlist response', data => {
        this.setState({contactlist : data})
        if (this.state.search) {
          const filter = {target:{value:this.state.search}}
          this.filterList(filter)
        } else {
        this.setState({contactlist_filtered : data})
      }
      })
      s.emit('contactlist',{'get':'it','ismanager':this.props.ismanager})
    }
  }

  filterList = (event)  => {
    let updatedList = this.state.contactlist;
    if (event) { this.setState({search : event.target.value })}
    console.log('start filter')
    console.log(this.state.search)
   if (!this.state.search || this.state.search == '') {
      console.log('not filtering')
      this.setState({contactlist_filtered : this.state.contactlist })
    } else {
    updatedList = updatedList.filter(item => {
      return item.name.first.toLowerCase().search(event.target.value.toLowerCase()) !== -1 ||
        item.name.last.toLowerCase().search(event.target.value.toLowerCase()) !== -1 ||
        item.wp.join(' ').toLowerCase().search(event.target.value.toLowerCase()) !== -1 ||
        item.username.toLowerCase().search(event.target.value.toLowerCase()) !== -1 ||
        item.email.toLowerCase().search(event.target.value.toLowerCase()) !== -1 ||
        item.tel.toLowerCase().search(event.target.value.toLowerCase()) !== -1

    });
    this.setState({contactlist_filtered: updatedList});}
  }

  handleClick = (event,e) => {
    if (!isMobile) {
      event.preventDefault();
      swal.fire({
        text : 'Telefonnnumer: '+e,
        confirmButtonText: 'Okay',

      })
    }
  }

  onCloseEdit = () => {
    this.setState({editusername : false})
  }

  addUser = () => {
    this.props.history.push('/neuerNutzer')
  }

  onCovidClick = (user) => {
    console.log(user)
    this.setState({modcovcert:user})

  }

  render () {
    return (
      <>
      <Paper style={center}>
        <Input type="search"  style={searchfield} placeholder="Suche" onChange={(event) => this.filterList(event)}/>
        {this.props.ismanager &&
          <Button variant="outlined" color="secondary" onClick={this.addUser}>
          Benutzer hinzufügen
          </Button>
        }
        <List>
        {this.state.contactlist_filtered && this.state.contactlist_filtered.map((e,i) =>
          <div>
          <Divider/>
          <ListItem key={e.username} className={e.cert && e.cert[0] && false ? 'list_has_cert' : ''}>
          <div style={left}>
            <div style={name}>{e.name.last+', '+e.name.first}</div>
            {this.props.ismanager && e.username+' ('+e.wp.join(', ')+')'}
            {this.props.ismanager && <br/>}
            {this.props.ismanager && e.tel}<br/>
            {this.props.ismanager && e.email}<br/>
          </div>
          <div style={right}>
          {this.props.ismanager && !isMobile &&
            <IconButton aria-label="Edit" onClick={(event) => this.setState({editusername : e.username})}>
            <EditIcon />
            </IconButton>
          }
           {this.props.ismanager && !isMobile &&
            <IconButton aria-label="Covid" onClick={(event) => this.onCovidClick(e.username)}>
            <HealingIcon />
            </IconButton>
          }         
            <IconButton aria-label="Call" href={"tel:"+e.tel} onClick={(event) => this.handleClick(event,e.tel)}>
              <CallIcon />
            </IconButton>
            </div>
            <ListItemSecondaryAction>
               <IconButton aria-label="SMS" onClick={(event) => this.handleClick(event,e.tel)} href={"sms:"+e.tel}>
                 <SmsIcon />
               </IconButton>
            </ListItemSecondaryAction>
            </ListItem>
            { (this.state.editusername == e.username) && <UserPreferences userdata={e} admindata={this.props.userdata} socket={this.props.socket} CloseEdit={this.onCloseEdit}/>}
          </div>
        )
        }
        </List>
        </Paper>
        {this.props.ismanager && !isMobile && <EditCovcert covcertuser={this.state.modcovcert} close={e => this.setState({modcovcert : false})} socket={this.props.socket}/>}
        </>

    )

  }



}

const blankstate = { 
  shots : '0/0',
  dos : '2021-01-01',
  history : [],
  valid : [false,{message:'nicht vorhanden'}]
}

class EditCovcert extends Component {
  constructor(props) {
    super(props)
    this.state = {
      shots : '0/0',
      dos : '1970-01-01',
      history : [],
      valid : [false,{message:'nicht vorhanden'}]
    }

  }



  componentDidMount () {
    if (this.props.covcertuser) {
      this.props.socket.emit('get cert info for',{username:this.props.covcertuser},data => {
        if (data)
          this.setState({...blankstate,...data})
      })
    }
  }
  

  componentDidUpdate (prevProps) {
    console.log(this.props.covcertuser)
    if (this.props.covcertuser !== prevProps.covcertuser) {
      if (this.props.covcertuser) {
        this.props.socket.emit('get cert info for',{username:this.props.covcertuser},data => {
          if (data)
            this.setState({...blankstate,...data})
        })
      }
    }
  }

  edituser = () => {
    this.props.socket.emit('update cert info for',{newcert:this.state,'username':this.props.covcertuser},data => {
      console.log(data)
      this.setState({...blankstate,editvals:false})
      this.props.close()
    })
  }

  closeThis = () => {
    this.setState({...blankstate,editvals:false})
    this.props.close()
  }

  render () {
    return (
      <Dialog open={this.props.covcertuser} onClose={e => this.closeThis()} className='covdialog'>
        <DialogTitle>Covid-Zertifikatsinfo</DialogTitle>
        <DialogContent>
          Aktuelles Z ist <b>{this.state.valid[0] ? 'gültig' : 'ungültig'}</b> weil <br/><i>{this.state.valid[1].message}</i><br/>          
          Verlauf:
          <List>
          {this.state.history.map((h,i) => <ListItem key={'hist_i'+i}>{h}</ListItem>)}
          </List>
        </DialogContent>
      <Button variant='contained' color='primary' onClick={e => this.setState({editvals : true})}>Manuell ändern</Button>
      {this.state.editvals && <>
            <TextField label="Datum der Impfung"  type="date" defaultValue={this.state.dos || '2022-01-01'} onChange={e => this.setState({'dos':e.target.value})}/>
            <TextField select label="Zertifiaktstyp" value={this.state.shots || '2/2'}  onChange={e => this.setState({'shots':e.target.value}) } variant="filled">
          {this.state.cert_captions.map((option) => (
            <MenuItem key={option.key} value={option.key}>
              {option.caption}
            </MenuItem>
          ))}
        </TextField>
        
      </>}
      <DialogActions>
        <Button onClick={e => this.closeThis()} variant='outlined'>Abbrechen</Button>
        <Button onClick={e => this.edituser()} disabled={!this.state.editvals} variant='contained'>Ändern</Button>
      </DialogActions>
      </Dialog>
    )
  }



}


export default withRouter(Contactlist)
